.restaurant-confirmation-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  column-gap: 5px;

  .restaurant-confirmation-label {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    color: #000000;
    white-space: nowrap;
  }

  .restaurant-confirmation-value {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    color: #6C6C6C;
  }
}
