.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: rgb(107, 114, 128);
  color: white;

  .not-found-content {
    text-align: center;
    margin-bottom: 4rem;

    h1 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    p {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
}
