.user-info-settings-container {
  width: 100%;
  margin-bottom: 20px;

  .user-info-form {
    grid-column-gap: 50px;

    & > div > div {
      margin-top: initial;
    }

    label {
      font-weight: 400;
      font-size: .6875rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      color: #8F8E94;
    }

    button {
      align-self: flex-end;
    }
  }

  .user-info-display-container {
    grid-column-gap: 70px;
  }

  .user-info-display-container, .user-info-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 0.75rem;

    .user-info-value-display {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #000000;
    }

    .user-info-button {
      background-color: #21377A;
      width: 125px;
      height: 35px;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      border-radius: 2px;
    }

    input {
      width: 175px;
      height: 35px;
      background-color: #FAFAFC;
      font-size: 0.75rem;
    }
  }
}
