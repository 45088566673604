.menu-section-selection-bar-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  .plus-icon {
    min-width: 14px;
    margin-right: 6px;
  }

  & > .arrow-icon {
    margin-right: 6px;
    &:hover {
      background-color: #f7f7f7;
    }
  }

  .menu-section-selection-bar-scroll-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 80px;

    .menu-section-input-container {
      margin-right: 15px;
      min-width: 220px;
    }
  }
}

@media (max-width: 768px) {
  .menu-section-selection-bar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    .plus-icon {
      min-width: 14px;
      margin-right: 0px;
      margin-left: -18px;
    }

    & > .arrow-icon {
      margin-right: 6px;
      &:hover {
        background-color: #f7f7f7;
      }
    }

    .menu-section-selection-bar-scroll-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;

      overflow-x: scroll;
      overflow-y: hidden;
      min-height: 52px;

      .menu-section-input-container {
        margin-right: 15px;
        min-width: 220px;
      }
    }
  }
}
