.multiple-size-price-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  fieldset {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
    padding: 0 10px;

    &:last-child {
      .radio-button {
        margin-top: 23px;
      }
    }
    &:not(:last-child) {
      .toggle {
        margin-top: 15px;
      }
    }

    &:last-child {
      .toggle {
        margin-top: 15px;
      }
    }
  }

  .default-container {
    border-radius: 0.125rem;
    background: #f7f7f7;

    legend {
      font-size: 0.625rem;
      letter-spacing: 0.4px;
      color: #000000;
      margin-left: 10px;
      background: #f7f7f7;
      padding: 2px 6px;
      border-radius: 0.125rem 0.125rem 0 0;
    }
  }

  .size-price-container {
    display: grid;
    grid-template-columns: [first] 170px [line2] 165px [line3] 55px [line4] 15px [end];
    justify-content: center;
    align-items: center;

    .price-input-toggle-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 6px;

      .multi-price-switch-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .multiprice-custom-tooltip-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: -22px;
          font-size: 0.725rem;
          line-height: 14px;
          letter-spacing: 0.5px;

          .price-label {
            font-size: 0.725rem;
            line-height: 22px;
            letter-spacing: 0.5px;
            color: #000000;
            margin-right: 10px;
            margin-left: 0px;
            margin-top: 24px;
          }

          svg {
            margin-top: 7px;
            margin-left: -10px;
          }

          .tooltip-wrapper {
            display: inline-block;
            position: relative;
            width: fit-content;
            margin-top: 15px;
            margin-right: -8px;

            .icon {
              cursor: pointer;
            }
            .description {
              font-family: 'Lato';
              display: none;
              text-align: left;
              padding: 1px 2px;
              border-radius: 5px;
              position: absolute;
              width: 130px;
              max-width: 300px;
              padding: 4px 4px;
              word-wrap: nowrap;
              border: 1px solid #979797;
              background-color: #979797;
              color: white;
              word-wrap: break-word;
              &::before {
                position: absolute;
                content: '';
                height: 0;
                width: 0;
                margin-top: -5px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid #979797;
                transform: rotate(-90deg);
              }
            }

            &:hover {
              .description {
                display: block;
              }
            }
          }
        }
      }

      .price-switch-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        justify-content: center;
        margin-left: -25px;

        .custom-tooltip-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: -20px;
          font-size: 0.725rem;
          line-height: 14px;
          letter-spacing: 0.5px;

          .price-label {
            font-size: 0.725rem;
            line-height: 22px;
            letter-spacing: 0.5px;
            color: #000000;
            margin-right: -10px;
            margin-left: 25px;
          }
        }
      }
    }

    .radio-button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    label {
      font-weight: 400;
      font-size: 0.725rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #000000;
      padding: 0;
    }

    input[type='text'] {
      width: 150px;
      height: 35px;
      font-weight: 400;
      font-size: 0.725rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #000000;
      padding: 0.5rem;
    }

    svg {
      justify-self: center;
      margin-top: 20px;
      margin-left: 10px;
    }

    .plus-icon {
      margin-top: 40px;
      margin-bottom: 0px;
      margin-left: -13px;
    }

    input[type='radio'] {
      align-self: flex-end;
      justify-self: center;
      margin: 0 0 10px 0;
    }

    span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.4px;
      color: #000000;
      align-self: flex-end;
      justify-self: center;
    }

    &.is-prix-fixe {
      input[type='text'] {
        color: #afafaf;
        background-color: #f7f7f7;
      }
    }
    .error-text {
      img {
        visibility: hidden;
      }
      font-size: 11px;
      height: fit-content;
      margin-top: 5px;
      min-height: 16.5px;
    }
  }

  .prix-prompt {
    font-size: 0.75rem;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6c6c6c;
    margin-top: 3px;
  }

  @media screen and (max-width: 768px) {
    input[type='text'] {
      width: 97px !important;
      height: 35px;
      font-weight: 400;
      font-size: 0.725rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #000000;
      padding: 0.5rem;
    }
    .size-price-container {
      display: grid;
      grid-template-columns: [first] 125px [line2] 115px [line3] 55px [line4] 15px [end];
      justify-content: center;
      align-items: center;

      .price-input-toggle-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 6px;

        .price-switch-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 15px;
          justify-content: center;

          .price-label {
            margin-top: 2px;
            font-size: 0.725rem;
            line-height: 22px;
            letter-spacing: 0.5px;
            color: #000000;
          }
        }
      }
    }
  }
}
