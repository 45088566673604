.menu-list-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;

  .menu-list-action-container {
    padding: 0 0 0 38px;
    height: 165px;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'menus menus'
      'sections sections';
  }

  .menu-selection-bar-container {
    grid-area: menus;
  }

  .menu-section-selection-bar-container {
    grid-area: sections;
  }

  .menu-list-view-loading {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .menu-list-view-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;

    .menu-list-action-container {
      padding: 0 0 0 38px;
      height: 115px;
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'menus menus'
        'sections sections';
    }

    .menu-selection-bar-container {
      grid-area: menus;
    }

    .menu-section-selection-bar-container {
      grid-area: sections;
      height: 40px;
      margin-bottom: 10px;
    }

    .menu-list-view-loading {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
