.mobile-navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;

  .mobile-navbar-logo img {
    width: 119px;
    height: 30px;
  }

  .mobile-navbar-dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: fit-content;

    .mobile-navbar-dropdown.dropdown-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      line-height: 22px;
      height: 25px;

      .select-wrapper {
        width: fit-content;
        border: 2px solid #ddd;

        select {
          width: 157px;
          height: 25px;
          text-align: left;
          border: 1px #ddd;
          padding-top: 5px;
          padding-bottom: 5px;
          border-right: 5px solid transparent;
          font-size: 11px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.5px;
          color: #6c6c6c;
        }
      }
    }
  }

  .mobile-navbar-burger {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
