.restaurant-wizard-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  border-left: 1px solid #efefef;
  background-color: #fbf9f9;

  .restaurant-wizard-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url('../../../../assets/images/RestaurantWizardHeader.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 318px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 165px auto;
    grid-template-areas:
      'close logo .'
      '. content .';

    &-close-icon {
      margin-top: 40px;
      margin-left: 39px;
      grid-area: close;
    }

    img {
      grid-area: logo;
      margin-top: 30px;
      align-self: center;
      justify-self: center;
    }

    &-content {
      grid-area: content;
      align-self: center;
      justify-self: center;

      .restaurant-wizard-header-title {
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  .restaurant-wizard-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    background-color: white;
    border-radius: 10px;
    padding: 30px 60px;

    .restaurant-wizard-back-button {
      grid-area: back;
      background: #9a9a9a;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 1.41818px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 14px;
      height: 35px;
      width: 167px;
      margin-top: 10px;
    }

    .restaurant-wizard-next-button {
      grid-area: next;
      justify-self: flex-end;
      background: #05944f;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 1.41818px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 14px;
      height: 35px;
      width: 167px;
      margin-top: 10px;
    }

    .restaurant-confirmation-page {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 12px;

      .restaurant-confirmation-label {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        color: #000000;
        white-space: nowrap;
      }

      .restaurant-confirmation-value {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        color: #6c6c6c;
      }

      .restaurant-confirmation-address-display {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 13px;

        .restaurant-confirmation-display-col-1,
        .restaurant-confirmation-display-col-2 {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      .restaurant-confirmation-socials-display {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 13px;
      }

      .restaurant-confirmation-img-display {
        width: 100%;

        .restaurant-confirmation-gallery-display {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 10px;
          flex-wrap: wrap;
        }

        label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }

        .image-preview-container {
          margin-top: 13px;

          img {
            width: 100px;
            height: 100px;
            aspect-ratio: 4/6;
            object-fit: cover;
          }
        }
      }

      .restaurant-wizard-confirm-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .restaurant-wizard-next-button:disabled {
          opacity: 0.3;
          cursor: initial;
          color: white;
        }
      }
    }

    .restaurant-layout-page {
      width: 100%;
      display: grid;
      grid-template-columns: 262px 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 20px;
      column-gap: 55px;
      grid-template-areas:
        'copy copy'
        'dropdown image'
        'back next';
      justify-content: space-between;

      .restaurant-layout-copy {
        grid-area: copy;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 22px;
        letter-spacing: 0.466667px;
        color: #6c6c6c;
      }

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .restaurant-layout-preview {
        grid-area: image;
      }

      .restaurant-wizard-layout-select {
        grid-area: dropdown;

        .select-wrapper {
          width: 100%;
          max-width: 262px;
          margin-top: 7px;
          select {
            text-align: left;
            width: 100%;
          }
        }
      }
    }

    .restaurant-photo-page {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 20px;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .restaurant-wizard-image-copy,
      .restaurant-wizard-image-copy i {
        margin-top: 7px;
        margin-bottom: 9px;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 22px;
        letter-spacing: 0.466667px;
        color: #6c6c6c;
      }

      .image-preview-container {
        img {
          width: 100px;
          height: 100px;
          aspect-ratio: 4/6;
          object-fit: cover;
        }
      }
      .restaurant-wizard-photo-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }

    .restaurant-location-page {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 200px 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 20px;
      grid-template-areas:
        'address address address'
        'city state zip'
        'back . next';
      justify-content: space-between;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      input,
      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .restaurant-wizard-address-inputs {
        grid-area: address;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .restaurant-wizard-street-address1-input,
        .restaurant-wizard-street-address2-input {
          width: 45%;
          input {
            max-width: 262px;
            width: 100%;
          }
        }
      }

      .restaurant-wizard-city-input {
        grid-area: city;

        input {
          max-width: 220px;
          width: 100%;
        }
      }

      .restaurant-wizard-state-select {
        grid-area: state;
        justify-self: center;
        width: fit-content;

        .select-wrapper {
          width: 136px;
          margin-top: 7px;
          select {
            text-align: left;
            width: 100%;
          }
        }
      }

      .restaurant-wizard-zip-code-input {
        grid-area: zip;
        justify-self: flex-end;

        input {
          max-width: 150px;
          width: 100%;
        }
      }
    }

    .restaurant-hours-page {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 20px;
      grid-template-areas:
        'picker picker'
        'notes notes'
        'back next';
      justify-content: space-between;

      .restaurant-hours-day-picker {
        grid-area: picker;
      }

      .restaurant-wizard-availability-notes-textarea {
        grid-area: notes;

        textarea {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.5px;
          height: 132px;
        }
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }
    }

    .restaurant-socials-page {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 20px;
      grid-template-areas:
        'social social'
        'back next';
      justify-content: space-between;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      .socials-label {
        @mixin social-icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .instagram-icon {
          @include social-icon;
          background: radial-gradient(
            99.05% 99.13% at 103.59% -19.85%,
            #ffdd55 0%,
            #ffdd55 10%,
            #ff543e 50%,
            #c837ab 100%
          );
        }

        .snapchat-icon {
          @include social-icon;
          background-color: #fffc00;
        }

        .tiktok-icon {
          @include social-icon;
          background: #ffffff;
          border: 1px solid black;
        }
      }

      input,
      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .restaurant-wizard-socials-inputs {
        grid-area: social;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 10px;

        .restaurant-wizard-socials-input {
          width: 45%;
          input {
            max-width: 262px;
            width: 100%;
          }
        }
      }
    }

    .restaurant-basic-info-page {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 33px;
      grid-template-areas:
        'name name'
        'description description'
        'description description'
        'description description'
        'phone email'
        'cuisine website'
        'button button';
      justify-content: space-between;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      input,
      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .restaurant-wizard-name-input {
        grid-area: name;

        input {
          max-width: 336px;
          width: 100%;
        }
      }

      .restaurant-wizard-description-input {
        grid-area: description;

        textarea {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.5px;
          height: 132px;
        }
      }

      .restaurant-wizard-phone-input {
        grid-area: phone;

        input {
          max-width: 220px;
        }
      }

      .restaurant-wizard-email-input {
        grid-area: email;
        justify-self: flex-end;

        input {
          width: 220px !important;
        }
      }

      .restaurant-wizard-cuisine-select {
        grid-area: cuisine;

        .select-wrapper {
          width: 100%;
          max-width: 220px;
          margin-top: 7px;
          select {
            text-align: left;
            width: 100%;
          }
        }
      }

      .restaurant-wizard-website-input {
        grid-area: website;
        justify-self: flex-end;

        input {
          width: 220px !important;
        }
      }

      .restaurant-wizard-info-next-button {
        grid-area: button;

        width: 100%;
        height: 35px;
        background: #05944f;
        border-radius: 3px;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 14px;
        text-align: center;
        letter-spacing: 1.41818px;
      }
    }
  }
}
