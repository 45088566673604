.change-password-form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 235px);
  column-gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 9px;

  .password-input {
    width: 225px;
    font-size: 0.75rem;

    button {
      top: 7px;
    }

    input {
      height: 35px;
      background-color: #FAFAFC;
      font-size: 0.75rem;
    }
  }

  .change-password-button {
    background-color: #21377A;
    width: 125px;
    height: 35px;
    font-size: 0.75rem;
    line-height: 22px;
    letter-spacing: 0.5px;
    border-radius: 2px;
  }
}
