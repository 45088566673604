.menu-section-edit-input {
  margin: 0 21px;
  min-width: 220px;
}

.menu-section-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 9.5px 0 0;
  padding: 11px 24px 11px 0;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: black;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 20px;
  white-space: nowrap;

  &.active {
    color: white;
    background-color: black;
  }

  &.is-hidden {
    color: #b8b8b8;
  }

  & span {
    margin-left: 8px;
  }

  .context-menu-action-container {
    margin-left: 18px;
    & .three-dot-icon {
      visibility: hidden;
    }
  }

  &.non-dragging:hover {
    color: black;
    background-color: #efebeb;

    .context-menu-action-container {
      & .three-dot-icon {
        visibility: visible;
      }
    }
  }

  &.active {
    color: white;
    background-color: black;
    font-weight: 400;

    &.is-hidden {
      color: #e8e8e8;
      background-color: #646464;
    }

    .context-menu-action-container {
      & .three-dot-icon {
        visibility: visible;
      }
    }
  }
}

@media (max-width: 768px) {
  .menu-section-edit-input {
    margin: 0 21px;
    min-width: 220px;
  }

  .menu-section-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 9.5px 0 0;
    padding: 8px 12px 8px 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1px;
    color: black;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 20px;
    white-space: nowrap;

    &.active {
      color: white;
      background-color: black;
    }

    &.is-hidden {
      color: #b8b8b8;
    }

    & span {
      font-family: Lato;
      font-size: 12px;
      margin-left: 0px;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 1px;
    }

    & svg {
      margin-top: 1px;
      width: 2px;
      height: 8px;
    }

    .context-menu-action-container {
      margin-left: 2px;
      & .three-dot-icon {
        visibility: hidden;
      }
    }

    &.non-dragging:hover {
      color: black;
      background-color: #efebeb;

      .context-menu-action-container {
        & .three-dot-icon {
          visibility: visible;
        }
      }
    }

    &.active {
      color: white;
      background-color: black;

      span {
        font-weight: 700;
        font-style: bold;
      }

      &.is-hidden {
        color: #e8e8e8;
        background-color: #646464;
      }

      .context-menu-action-container {
        & .three-dot-icon {
          visibility: visible;
        }
      }
    }
  }
}
