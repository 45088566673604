@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.button.mobile-download-csv-button {
  display: block;
  width: 114px;
  height: 25px;
  font-size: 10px;
  background: #14a68c;
  margin-right: 7px;
  margin-left: auto;
}

.ag-mobile-theme-alpine {
  @include ag-theme-alpine(
    (
      // use theme parameters where possible
      header-background-color: white,
      odd-row-background-color: white,
      row-hover-color: white,
      column-hover-color: white,
      header-foreground-color: black,
      row-height: 90px,
      header-height: 40px,
      font-size: 0.75rem,
      borders: false
    )
  );

  .ag-ltr .ag-floating-filter-button {
    margin-left: 10px;
    margin-right: 20px;
  }

  .no-border {
    margin-left: 0;
  }
  .no-border.ag-cell:focus {
    border: none !important;
    outline: none;
  }

  .ag-cell {
    padding: 0 !important;
    display: flex;
  }

  .hidden-cell-value {
    opacity: 0.2;
  }

  .ag-row-selected,
  .ag-row-hover {
    background: rgba(196, 196, 196, 0.2);

    & .context-menu-action-container {
      visibility: visible;
    }
  }

  .ag-ltr .ag-cell-focus {
    border: none;
    background: rgba(135, 226, 255, 0.2);
  }

  .ag-root-wrapper {
    font-size: 0.6875rem;
  }

  .name-header {
    border-bottom: 0.5px solid #c7c7cd !important;

    .ag-header-cell-label {
      margin-left: 0px;
    }
  }

  .custom-header {
    padding: 0 !important;
    border-bottom: 0.5px solid #c7c7cd !important;
  }

  .custom-header,
  .name-header {
    font-weight: 700 !important;
    font-size: 12px;
    letter-spacing: 1 !important;
  }
}

.item-row {
  border-bottom: 0.5px solid #c7c7cd !important;
}
