.draggable-name-cell-container {
  display: grid;
  grid-template-columns: 25px 40px 50px 3fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    ". . . ."
    "action drag image name"
    ". . . .";
  min-width: 245px;
  height: 100%;

  align-items: center;

  font-size: .875rem;
  font-weight: 300;
  letter-spacing: .5px;
  cursor: pointer;
  padding-right: 10px;

  & .context-menu-action-container {
    grid-area: action;
    visibility: hidden;
    justify-self: center;
  }

  & .draggable-icon {
    grid-area: drag;
    margin-right: 16px;
    cursor: grab;
    justify-self: center;
  }

  .image-container {
    grid-area: image;
    cursor: pointer;
    position: relative;
    width: max-content;

    img {
      width: 37.5px;
      height: 75px;
      object-fit: cover;
    }

    .icon-container {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 3px;
      white-space: nowrap;
      align-items: center;
      padding: 3px;

      .duplicate-icon,
      .play-icon {
        width: 11px;
        height: 11px;
      }
    }

    & #logo {
      grid-area: image;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }



  .name-and-tag-container {
    grid-area: name;
    padding-left: 10px;

    .name-container {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: inherit;
      -webkit-line-clamp: 6;

      line-height: 15px;
      word-break: break-word;

      &.has-tag {
        -webkit-line-clamp: 3;
        align-self: flex-end;
      }
    }

    .name-cell-tag-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      flex-wrap: wrap;

      .special-tag {
        margin-right: 3px;
      }
    }
  }
}

.promotion-name-cell .non-draggable-name-cell-container,
.promotion-name-cell .draggable-name-cell-container{
  & img {
    height: 55px;
    width: 50px;
    object-fit: cover;
  }
}

.non-draggable-name-cell-container {
  display: grid;
  grid-template-columns: 25px 50px 3fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    ". . ."
    "action image name"
    ". . .";
  min-width: 245px;
  height: 100%;

  align-items: center;

  font-size: .875rem;
  font-weight: 300;
  letter-spacing: .5px;
  cursor: pointer;

  & .context-menu-action-container {
    grid-area: action;
    visibility: hidden;
    justify-self: center;
  }

  .image-container {
    grid-area: image;
    cursor: pointer;
    position: relative;
    width: max-content;

    & img {
      width: 37.5px;
      height: 75px;
      cursor: pointer;
      object-fit: cover;
    }

    & #logo {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  .name-container {
    padding-left: 10px;
    grid-area: name;
    line-height: 15px;
    word-break: break-word;
  }
}
