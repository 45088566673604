.multi-menu-section-form {
  border-radius: 5px;
  width: 100%;
  max-width: 1139px;
  line-height: 22px;
  padding: 5px 5px;

  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;
  }

  .added-sections-header {
    width: 55%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 15px;
  }

  .modal-body {
    .menu-section-inputs-container {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;

      .menu-section-formik-input {
        display: flex;
        flex-direction: column;
      }

      .menu-section-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 20px;
        width: 100%;
        margin-bottom: 10px;

        .menu-section-input {
          width: 185px;
        }

        .menu-section-area {
          max-width: 743px;
        }

        .menu-section-input,
        .menu-section-area {
          input,
          textarea {
            height: 35px;
            padding: 8px;

            &:disabled {
              background: #f7f7f7;
              color: #afafaf;
              cursor: not-allowed;
            }
          }

          label {
            font-weight: 500;
            font-size: 0.8125rem;
            line-height: 22px;
            letter-spacing: 1.1375px;
            color: #6c6c6c;
          }
        }

        .menu-section-area {
          width: 100%;

          label + div + div {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .added-sections-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-right: 15px;

      .menu-section-title-description {
        margin-left: 30px;
      }
    }

    .modal-body {
      .menu-section-inputs-container {
        .menu-section-input-container {
          flex-direction: row;
          align-items: flex-start;
          column-gap: 30px;
          width: 100%;
          margin-bottom: 10px;

          .menu-section-input {
            width: 95px;
          }
        }
      }
    }
  }
}
