.menu-top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;

  .menu-top-selection {
    font-weight: 300;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 0 15px;

    &:first-child {
      border-right: 1pt solid black;
    }

    &:nth-child(2) {
      border-left: 1pt solid black;
    }

    &.active {
      font-weight: 600;
    }
  }

  .add-button-container, .add-modifier-button  {
    align-self: center;
    margin-left: auto;
  }

  .add-modifier-button {
    font-size: .875rem;
    width: 182px;
    height: unset;
    padding: 6.5px;
    background: #05944f;
  }
}
