.add-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .add-menu-item-button,
  .add-drink-item-button {
    height: 37px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 17px;
    text-align: center;
    letter-spacing: 2.1px;
  }

  .add-menu-item-button {
    width: 176px;
    background-color: #14a68c;
    margin-right: 7.5px;
  }

  .add-drink-item-button {
    width: 137px;
    background-color: #1460a6;
    margin-left: 7.5px;
  }
}

@media (max-width: 768px) {
  .add-button-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;

    .add-menu-item-button,
    .add-drink-item-button {
      height: 28px;
      font-weight: 500;
      font-size: 10px;
      line-height: normal;
      text-align: center;
      letter-spacing: 1px;
    }

    .add-menu-item-button {
      width: 109px;
      background-color: #14a68c;
      margin-left: 10.5px;
      margin-right: 0px;
    }

    .add-drink-item-button {
      width: 109px;
      background-color: #1460a6;
      margin-left: 7.5px;
    }
  }
}
