.modal-content.announcement-modal {
  border-radius: 20px;
  width: 892px;
  line-height: 22px;
  padding: 25px 50px;
  overflow: scroll;

  .modal-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0 10px;
  }

  .modal-body {
    overflow-y: auto;
    .error-text {
      font-size: 0.75rem;
    }
  }

  .modal-footer {
    justify-content: flex-end;
    padding: 15px 0 0 0;
    width: 305px;
  }

  .modal-title {
    letter-spacing: 1.1375px;
  }

  .modal-cancel-button,
  .modal-confirm-button {
    color: white;
    letter-spacing: 1.41818px;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 7px;
    line-height: 14px;
    text-align: center;
    width: 119px;
    height: 35px;
  }

  .modal-cancel-button {
    background-color: #a73333;
    margin-right: 12px;
  }

  .modal-confirm-button {
    background: #05944f;
  }

  .select-media-button {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 1.41818px;
    width: 135px;
    height: 35px;
    border-radius: 3px;
    background: #7e7e7e;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 10px;
    overflow: scroll;

    .modal-header {
      padding: 10px 20px;
      margin-left: -10px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .modal-footer {
      justify-content: space-between;
    }
  }
}

.announcement-type-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: fit-content;

  .promotion-preview-action-container {
    width: fit-content;

    &:hover {
      background-color: #cccccc;
      border-radius: 50%;
    }
  }

  .announcement-type-label {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 22px;
    letter-spacing: 1.1375px;
    color: #6c6c6c;
    white-space: nowrap;
    word-break: keep-all;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-bottom: 0.5rem;
  }

  label > span {
    display: inline-flex;
  }

  .announcement-modal-type-display {
    width: 182px;
    text-align: start;
  }

  .announcement-modal-type-select.dropdown-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    select {
      height: 35px;
      width: 182px;
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    .announcement-modal-type-display {
      width: 137px;
      text-align: start;
    }

    .announcement-modal-type-select.dropdown-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      select {
        width: 137px;
        height: 35px;
        text-align: left;
        font-size: 12px;
      }
    }
  }
}
