.multiple-media-uploader-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  .multiple-media-uploader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;

    &:first-child {
      column-gap: 0;
    }

    .multiple-media-drag-and-drop {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 10px;

      .multiple-media-preview-container {
        width: 110px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        column-gap: 8px;

        .multiple-media-preview {
          width: 80px;
          height: 160px;

          cursor: initial;

          &.multiple {
            cursor: grab;

            transform: translateY(0);
            transition: 100ms transform ease-out;

            &:hover {
              transform: translateY(-3px);
              transition: 100ms transform ease-in;
            }

            &.is-dragging {
              cursor: grabbing;
            }
          }

          .image-container {
            width: inherit;
            height: inherit;
            position: relative;

            .play-icon {
              width: 14px;
              height: 12px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 3;
              margin: 10px 10px 0 0;
            }
          }

          .media-preview {
            width: inherit;
            height: inherit;
            object-fit: cover;
          }
        }

        .delete-icon,
        .edit-icon {
          margin-bottom: 4px;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .multiple-media-upload-container {
      width: 265px;
      height: 160px;
      background: #fafafc;
      border: 1px solid #979797;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      font-size: 11px;
      line-height: 13px;
      color: #707982;

      .media-upload-icon {
        margin-bottom: 6px;
      }

      input[type='file'] {
        display: none;
      }
    }
  }

  .media-upload-error-message {
    visibility: hidden;

    &.has-error {
      visibility: visible;
    }
  }

  @media screen and (max-width: 768px) {
    .multiple-media-uploader {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 10px;

      .multiple-media-drag-and-drop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 15px;

        .multiple-media-preview-container {
          width: 100px;
        }
      }
    }
  }
}
