.alert-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(240, 240, 240, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 15;
  cursor: pointer;

  &-container {
    width: 500px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 25px;

    .alert-modal-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1rem;
        text-transform: uppercase;
      }

      p {
        font-weight: 500;
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 1.1375px;
        color: #6c6c6c;
      }
    }

    .alert-modal-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 10px;
      padding-top: 10px;

      .alert-modal-cancel-button,
      .alert-modal-confirm-button {
        color: white;
        letter-spacing: 1.41818px;
        font-size: 0.75rem;
        font-weight: 600;
        border-radius: 3px;
        line-height: 14px;
        text-align: center;
        width: 119px;
        height: 35px;
      }

      .alert-modal-cancel-button {
        background-color: #a73333;
        margin-right: 12px;
      }

      .alert-modal-confirm-button {
        background: #05944f;
      }
    }
  }
}
