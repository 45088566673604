.sidebar-container {
  z-index: 1;
  width: 225px;
  height: 100vh;
  background: white;
  flex-shrink: 0;
  text-align: center;
  row-gap: 25px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 2fr 5fr;
  grid-template-areas:
    'header header'
    'links links'
    'logout logout';

  .sidebar-header-container {
    grid-area: header;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1.25fr 0.5fr;
    grid-template-areas:
      'logo logo logo'
      'info info info'
      'info info info';

    .sidebar-logo-container {
      grid-area: logo;
      padding: 35px 0;

      .sidebar-logo {
        height: 30px;
        margin: 0 auto;
      }
    }

    .sidebar-profile-container {
      width: 100%;
      grid-area: info;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .sidebar-image-container {
        grid-area: image;
        align-self: center;

        .sidebar-restaurant-icon {
          border-radius: 50%;
          margin: 7px 0 auto 0;
          height: 90px;
          width: 90px;
          aspect-ratio: 4/6;
          object-fit: cover;
        }
      }

      .sidebar-info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .sidebar-restaurant-name {
          font-size: 0.875rem;
          margin-bottom: 10px;
        }

        .sidebar-restaurant-dropdown {
          font-size: 0.75rem;
          margin: 10px 0;
          line-height: 22px;
          letter-spacing: 0.5px;
          color: #6c6c6c;
          min-width: 200px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        .sidebar-restaurant-id {
          font-size: 0.625rem;
        }
      }
    }
  }

  .sidebar-link-container {
    grid-area: links;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .sidebar-link {
      width: 100%;

      & > a {
        border-top: 1px solid #efefef;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 100%;
        height: 59px;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 1.225px;
        text-transform: uppercase;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.6;
        cursor: pointer;
        column-gap: 16px;
        padding: 0 28px;

        &:hover {
          background-color: #f7f7f7;
        }

        & svg {
          width: 16px;
        }
      }
      &.selected {
        background-color: #f7f7f7;
      }
    }
  }

  .logout-container {
    grid-area: logout;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .logout-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 22px;
      letter-spacing: 1.225px;
      color: #6c6c6c;
      mix-blend-mode: normal;
      opacity: 0.6;
      cursor: pointer;
      column-gap: 13px;
    }
  }
}
