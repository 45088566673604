.press-article-container {
  width: 580px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, auto);
  row-gap: 10px;
  gap: 10px;
  grid-template-areas:
    "article-name publication"
    "article-link ."
    "article-description article-photo"
    "add-button delete-button";
  justify-content: space-between;

  label {
    font-size: 0.8125rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    font-weight: 400;
    margin-block-end: 0;
  }

  input {
    height: 35px;
    width: 265px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .error-text {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .press-page-article-name-input {
      grid-area: article-name;
  }

  .press-page-article-publication-input {
      grid-area: publication;
      justify-self: flex-end;
  }

  .press-page-article-link-input {
      grid-area: article-link;
  }

  .press-page-article-description-input {
      grid-area: article-description;

      textarea {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
        height: 160px;
        width: 265px;
      }
    }

  .press-page-article-photo {
      grid-area: article-photo;
      justify-self: flex-end;

      .error-text {
        margin-top: -5px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: rgb(248 113 113);
      }

      .press-page-profile-photo-upload {
        padding-top: 8px;

        .image-upload-container {
          height: 160px;
          margin-top: 7px;
        }

        .button.select-media-button {
          width: 265px;
          height: 160px;
          background: #fafafc;
          border: 1px solid #979797;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          border-radius: 0;
          font-size: 11px;
          line-height: 13px;
          color: #707982;
          margin-bottom: 10px;

          .media-upload-icon {
            margin-bottom: 6px;
          }
        }

        .multiple-media-preview-container {
          width: 265px;

          .multiple-media-preview {
            width: 245px;
          }
        }

          .image-preview-container {
            width: 265px;
            height: 160px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 8px;

            .image-preview-image {
              width: 265px;
              height: inherit;
              margin-right: 5px;
              object-fit: cover;
            }

            svg {
              height: 12px;
              align-self: flex-end;
            }
          }
      }
  }

      .add-article-button {
        grid-area: add-button;
        height: 20px;
        width: 170px;
        justify-self: flex-start;
        background: transparent;
        color: black;
        letter-spacing: 1.41818px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 14px;
        margin-top: 10px;
        margin-left: -16px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          margin-right: 5px;
          height: 12px;
        }
      }

  .delete-article-button {
    grid-area: delete-button;
    align-self: flex-end;
    height: 20px;
    width: 200px;
    background: transparent;
    color: black;
    letter-spacing: 1.41818px;
    font-weight: 800;
    font-size: 1rem;
    line-height: 14px;
    margin-bottom: 25px;
    margin-top: 7px;
    margin-left: -2px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
      height: 12px;
    }
  }
}
