.day-hour-display-container {
  width: 100%;

  .hours-label {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    color: #000000;
    white-space: nowrap;
  }

  .error-text {
    font-weight: 600;
    font-size: 0.925rem;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .day-hour-display {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 20px;

    .day-hour-label {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.9625px;
      color: #6C6C6C;
      min-width: 100px;
    }

    .day-hour-time-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.9625px;
      color: #6C6C6C;
    }
  }
}
