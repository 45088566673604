.custom-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 80px;
  width: 100%;
  height: 100%;

  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 15px;
  cursor: pointer;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;
    width: inherit;
  }
}
