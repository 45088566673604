.settings-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F1F1F1;
  justify-content: center;
  align-items: center;
  padding: 57px 0;

  .settings-container {
    width: 95%;
    height: fit-content;
    min-height: 710px;
    background-color: white;
    box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.01);
    border-radius: 9px;

    .settings-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 50px;

      .edit-restaurant-button {
        align-self: flex-end;
        width: 125px;
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.5px;
        border-radius: 2px;
        color: #FFFFFF;
        background: #21377A;
      }

      .settings-selector {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .settings-selector-button {
          width: 317px;
          height: 70px;
          font-weight: 600;
          font-size: 1rem;
          line-height: 19px;
          letter-spacing: 1px;
          color: #000000;
          padding: 26px 50px;
          white-space: nowrap;
          background-color: #F1F1F1;
          border-radius: initial;

          &:first-child {
            border-top-left-radius: 9px;
          }

          &.active {
            background-color: white;
          }
        }
      }
    }

    .settings-content {
      padding: 34px 50px;

      hr {
        border: 1px solid #DEDEDE;
        width: 100%;
        margin: 15px 0 30px 0;
      }
    }
  }
}
