.button {
  width: 182px;
  height: 48px;
  color: white;
  background-color: #217a22;
  font-size: 1.25rem;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;

  &:disabled {
    background: #f7fff3;
    color: #222222;
  }
}

.button-loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #979797;
}
