.dropdown-container {
  width: 100%;

  .select-wrapper {
    width: fit-content;
    border: 1px solid #979797;

    select {
      text-align: center;
      height: 40px;
      width: 100%;
      font-size: inherit;
      padding: 10px 5px 10px 10px;
      border-right: 10px solid transparent
    }
  }
}
