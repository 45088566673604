.email-manager-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #efefef;
  overflow-x: scroll;
  row-gap: 20px;

  .custom-cell-container {
    padding-left: 20px;

    &.date-time-cell {
      padding-left: initial;
    }
  }
}

.email-manager-page-loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #efefef;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.email-manager-mobile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #efefef;
  overflow-x: scroll;
  row-gap: 20px;

  .custom-cell-container {
    padding-left: 5px;
    font-family: Lato;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 14.4px;
    letter-spacing: 0.7px;

    &.date-time-cell {
      padding-left: initial;
    }
  }
}
