.modal-content.multi-menu-section-modal {
  border-radius: 5px;
  width: 80%;
  max-width: 1139px;
  line-height: 22px;
  padding: 25px 50px;

  .modal-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
  }

  .modal-body {
    .menu-section-inputs-container {
      display: flex;
      flex-direction: column-reverse;

      .menu-section-formik-input {
        display: flex;
        flex-direction: column;

        .error-text {
          font-size: 10px;
        }
      }

      .menu-section-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 20px;
        width: 100%;

        .menu-section-input {
          width: 185px;
        }

        .menu-section-area {
          max-width: 743px;
        }

        .menu-section-input,
        .menu-section-area {
          input,
          textarea {
            height: 35px;
            padding: 8px;

            &:disabled {
              background: #f7f7f7;
              color: #afafaf;
              cursor: not-allowed;
            }
          }

          label {
            font-weight: 500;
            font-size: 0.8125rem;
            line-height: 22px;
            letter-spacing: 1.1375px;
            color: #6c6c6c;
          }
        }

        .menu-section-area {
          width: 100%;

          label + div + div {
            margin-top: 0;
          }
        }

        .menu-section-icon {
          align-self: center;
          margin-top: 20px;

          &.has-error {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .modal-footer {
    justify-content: flex-end;
    padding: 15px 4.5% 0 4.5%;
  }

  .modal-title {
    letter-spacing: 1.1375px;
  }

  .modal-cancel-button,
  .modal-confirm-button {
    color: white;
    letter-spacing: 1.41818px;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 3px;
    line-height: 14px;
    text-align: center;
    width: 119px;
    height: 35px;
  }

  .modal-cancel-button {
    background-color: #a73333;
    margin-right: 12px;
  }

  .modal-confirm-button {
    background: #05944f;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    width: 80%;

    .modal-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 10px 10px 0 10px;

      svg {
        width: 12px;
        height: 20px;
      }
    }

    .modal-body {
      .menu-section-inputs-container {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 10px;
        max-height: 80vh;
        overflow-y: auto;

        .menu-section-input-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          row-gap: 10px;
          width: 100%;
          margin-bottom: 15px;

          .menu-section-input {
            width: 137px;
          }

          .menu-section-area {
            max-width: 743px;
            height: 118px;
            margin-bottom: 6px;
            input,
            textarea {
              height: 118px;
              padding: 8px;

              &:disabled {
                background: #f7f7f7;
                color: #afafaf;
                cursor: not-allowed;
              }
            }
          }
          .menu-section-input {
            input,
            textarea {
              height: 35px;
              padding: 8px;

              &:disabled {
                background: #f7f7f7;
                color: #afafaf;
                cursor: not-allowed;
              }
            }
          }

          .menu-section-icon {
            align-self: flex-start;

            &.has-error {
              margin-top: 0px;
            }
          }
        }
      }
    }

    .modal-footer {
      justify-content: flex-end;
      padding: 0px 4.5% 0 4.5%;
      margin-left: -8px;
      margin-top: -5px;
    }

    .modal-title {
      letter-spacing: 1.1375px;
    }

    .modal-cancel-button,
    .modal-confirm-button {
      color: white;
      letter-spacing: 1.41818px;
      font-size: 0.75rem;
      font-weight: 600;
      border-radius: 6px;
      line-height: 14px;
      text-align: center;
      width: 119px;
      height: 35px;
    }

    .modal-cancel-button {
      background-color: #a73333;
      margin-right: 12px;
    }

    .modal-confirm-button {
      background: #05944f;
      margin-right: -11px;
    }
  }
}
