input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 12px;
  height: 12px;
  border: 1pt solid #DEDEDE;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em black;
  }

  &:checked::before {
    transform: scale(1);
  }
}