input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #DEDEDE;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  -ms-transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
  
  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    -webkit-transition: 120ms transform ease-in-out;
    -moz-transition: 120ms transform ease-in-out;
    -ms-transform: scale(0);
    box-shadow: inset 1em 1em black;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
    -ms-transform: scale(1);
  }
}
