.menu-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #EFEFEF;
  overflow-x: scroll;
}

.menu-page-loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #EFEFEF;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
}

