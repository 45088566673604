.tooltip-wrapper {
  display: inline-block;
  position: relative;
  width: fit-content;

  .icon {
    cursor: pointer;
  }
  .description {
    font-family: 'Lato';
    display: none;
    text-align: left;
    padding: 1px 2px;
    border-radius: 5px;
    position: absolute;
    width: 130px;
    max-width: 300px;
    padding: 4px 4px;
    word-wrap: nowrap;
    border: 1px solid #979797;
    background-color: #979797;
    color: white;
    word-wrap: break-word;
    &::before {
      position: absolute;
      content: '';
      height: 0;
      width: 0;
      margin-top: 10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #979797;
      transform: rotate(-90deg);
    }
  }

  &:hover {
    .description {
      display: block;
    }
  }
}
.right {
  .description {
    top: 7px;
    margin-left: 110%;
    &::before {
      left: -10px;
      top: 10px;
      transform: rotate(-90deg);
    }
  }
}

.bottom {
  .description {
    margin-top: 10%;
    left: 30%;
    &::before {
      transform: rotate(0);
      margin-top: -10%;
    }
  }
}

.top {
  .description {
    bottom: 110%;
    margin-left: 30%;
    &::before {
      transform: rotate(180deg);
      bottom: -8px;
    }
  }
}

.left {
  .description {
    right: 0;
    margin-right: 110%;
    top: 0;
    &::before {
      transform: rotate(90deg);
      right: 0;
      margin-right: -10px;
      margin-top: 20%;
    }
  }
}
