.available-modifiers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 4px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .search-modifiers-input {
    width: 100%;
    input {
      height: 35px;
    }
  }

  .available-modifiers-list {
    width: 100%;
    height: 175px;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .no-modifiers-copy {
      margin: auto;
      font-size: 14px;
      color: #9E9E9E;
      font-style: italic;
      font-weight: 600;
      text-align: center;
    }

    .modifier-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      column-gap: 6px;
      background: #ffffff;
      border-bottom: 1px solid #979797;
      padding: 6px;

      .add-modifier-button {
        padding: 2px 6px;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 12px;
        margin-right: 8px;
        background-color: #4CAF50;
        color: white;
        width: fit-content;
        height: fit-content;
        line-height: unset;
        letter-spacing: unset;

        &:hover {
          background-color: #45a049; /* Darker Green on hover */
        }
      }
    }
  }
}