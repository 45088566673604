.wizard-progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wizard-progress-bar-copy {
    min-width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 8px;
    color: white;

    span {
      font-size: 1rem;
      font-weight: 300;

      &.current {
        font-weight: 600;
      }
    }
  }

  .wizard-progress-bar {
    display: flex;
    width: 500px;
    height: 14px;
    margin-bottom: 10px;

    span {
      height: 100%;
      background-color: #0f0f0f;

      &.active {
        background-color: #00bd9c;
      }
    }

    span:first-child {
      border-radius: 10px 0 0 10px;
    }

    span:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  @media (max-width: 768px) {
    width: 250px;

    .wizard-progress-bar-copy {
      min-width: 125px;
      column-gap: 60px;

      span {
        font-size: 0.7rem;
        margin-bottom: -5px;
        margin-left: -15px;

        &.current {
          margin-left: 5px;
        }
      }
    }
    .wizard-progress-bar {
      width: 250px !important;
      height: 8px;
    }
  }
}
