.media-library-item-container {
  width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 6px;
  cursor: initial;

  &.is-selected > .media-library-item-image-container {
    border: 3px solid #05944f;
  }

  .media-library-item-image-container {
    height: 231px;
    position: relative;
    display: block;
    transition: border-color 0.3s ease;
    border: 3px solid #fff;

    .play-icon,
    .delete-icon {
      position: absolute;
      color: white;
      cursor: pointer;
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    }

    .play-icon {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 30px;
      width: 25px;
    }

    .delete-icon {
      display: none;
      bottom: 10px;
      right: 10px;
      transition: opacity 0.3s ease;
    }

    .select-media-button {
      position: absolute;
      display: none;
      bottom: 10px;
      right: 10px;
      transition: opacity 0.3s ease;
      padding: 3px 5px;
      background-color: #05944f;
      border-radius: 2px;
      color: #fff;
      text-align: center;
      font-size: 7px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0.25px;

      &.selected {
        background-color: #a73333;
      }
    }

    &:hover {
      border: 3px solid #05944f;

      .delete-icon,
      .select-media-button {
        display: block;
      }
    }

    .media-library-item-image {
      height: 225px;
      object-fit: cover;
      display: block;

      video {
        display: block;
        height: 225px;
        object-fit: cover;
      }
    }
  }

  .media-library-name {
    color: #707982;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 40px;
    max-width: 120px;
    word-break: break-all;
  }

  @media screen and (max-width: 768px) {
    width: 150px;

    .media-library-item-image-container {
      height: 155px;
      width: 150px;

      .delete-icon {
        display: none;
        bottom: 3px;
        right: 3px;
        transition: opacity 0.3s ease;
        width: 12px;
        height: 12px;
      }

      .media-library-item-image {
        height: 150px;
        width: 150px;
        object-fit: cover;
        display: block;

        video {
          display: block;
          height: 150px;
          width: 150px;
          object-fit: cover;
        }
      }
    }
  }
}
