.initial-menus-view-container {
  display: inline-block;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../../../src/assets/images/BackgroundBowls.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 250px;
  right: 0;
  overflow: scroll;
  padding-top: 10%;

  .initial-menus-view-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr .75fr .5fr .75fr;
    grid-template-areas:
    ". . logo . ."
    ". hero hero hero ."
    ". cta cta cta ."
    ". . button . .";
    color: white;

    img {
      grid-area: logo;
      justify-self: center;
    }

    h1 {
      grid-area: hero;
      justify-self: center;
      align-self: center;
      font-size: 2.625rem;
      line-height: 40px;
      letter-spacing: 0.66px;
      font-weight: 600;
      margin-block-start: 0;
      margin-block-end: 0;
      text-align: center;
    }

    .call-to-action {
      grid-area: cta;
      justify-self: center;
      align-self: center;
      font-size: 2rem;
      max-width: 515px;
      text-align: center;
      letter-spacing: .5px;
      font-weight: 300;
    }

    button {
      grid-area: button;
      align-self: flex-end;
      justify-self: center;
      height: 48px;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 1px;
      background-color: #05944F;
    }
  }
}
