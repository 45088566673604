.account-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .account-info-input-header {
    font-weight: 400;
    font-size: .6875rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    color: #8F8E94;
    margin-bottom: 9px;
  }

  .error-text {
    margin-top: 6px;
  }

  .account-info-stripe-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .account-info-current-services, .account-info-new-service {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .manage-services-button {
        background: #21377A;
      }

      .add-service-button {
        background: #05944F;
      }

      .manage-services-button, .add-service-button {
        width: 150px;
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.5px;
        border-radius: 2px;
        color: #FFFFFF;
      }
    }
  }
}
