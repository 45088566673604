.mobile-menu-top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
  width: 100%;

  .select-wrapper {
    width: fit-content;
    border: 2px solid #ddd;

    select {
      width: 92px;
      height: 25px;
      text-align: left;
      border: 1px #ddd;
      padding-top: 5px;
      padding-bottom: 5px;
      border-right: 5px solid transparent;
      font-size: 10px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #6c6c6c;
    }
  }

  .mobile-add-modifier-button {
    font-size: 10px;
    width: 160px;
    height: 25px;
    background: #05944f;
  }
}
