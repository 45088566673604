.no-image-display {
  width: 120px;
  height: 120px;
  background: #FAFAFC;
  border: 1px solid #979797;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 11px;
  line-height: 13px;
  color: #707982;

  .no-image-icon {
    margin-bottom: 6px;
  }
}
