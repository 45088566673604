.download-menu-modal {
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    column-gap: 14px;
    padding: 0 10px;

    .modal-pdf-button,
    .modal-word-doc-button {
      padding: 0 12px;
      width: fit-content;
      white-space: nowrap;
      height: 35px;
      color: white;
      letter-spacing: 1.41818px;
      font-size: 0.75rem;
      font-weight: 600;
      border-radius: 3px;
      line-height: 14px;
      text-align: center;
    }

    .modal-word-doc-button {
      background-color: #2b579a;
    }

    .modal-pdf-button {
      background-color: #7e7e7e;
    }
  }

  @media (max-width: 767px) {
    .modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 10px 0px 10px;

      svg {
        width: 10px;
        height: 15px;
        margin-right: -5px;
      }
    }
    .modal-footer {
      padding: 0px 10px;
      display: flex;
      justify-content: flex-start;
      column-gap: 10px;

      .modal-pdf-button,
      .modal-word-doc-button {
        padding: 0 12px;
        width: 102px;
        white-space: nowrap;
        height: 35px;
        color: white;
        letter-spacing: 1.41818px;
        font-size: 0.75rem;
        font-weight: 600;
        border-radius: 7px;
        line-height: 14px;
        text-align: center;
      }

      .modal-word-doc-button {
        background-color: #2b579a;
      }

      .modal-pdf-button {
        background-color: #a73333;
        margin-right: 10px;
      }
    }
  }
}
