.promotions-top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;
  height: 90px;
  width: 100%;

  .button.add-promotion-button {
    align-self: center;
    margin-left: auto;
    font-size: 0.875rem;
    width: 182px;
    height: 37px;
    background: #14a68c;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
    height: 45px;

    .button.add-promotion-button {
      width: 114px;
      height: 25px;
      font-size: 0.625rem;
    }

    h1 {
      font-size: 0.9375rem;
      font-family: Lato;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
    }
  }
}
