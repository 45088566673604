.promotions-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #efefef;
  overflow-x: scroll;
  row-gap: 10px;
}

.promotions-page-loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #efefef;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
