.selected-modifiers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #C0C0C0;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .selected-modifiers-list {
    width: 100%;
    height: 150px;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .no-modifiers-copy {
      margin: auto;
      font-size: 14px;
      color: #9E9E9E;
      font-style: italic;
      font-weight: 600;
      text-align: center;
    }

    .modifier-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      background: #ffffff;
      border-bottom: 1px solid #979797;
      padding: 6px;

      .draggable-icon {
        cursor: move;
      }

      .remove-modifier-button {
        padding: 2px 6px;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 12px;
        margin-right: 8px;
        background-color: #e74c3c;
        color: white;
        width: fit-content;
        height: fit-content;
        line-height: unset;
        letter-spacing: unset;

        &:hover {
          background-color: #a73333;
        }
      }

      &.is-dragging {
        background-color: #f5f5f5;  /* Lighter background */
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);  /* Box shadow for depth */
        opacity: 0.9;  /* Slightly transparent */
        cursor: grabbing;  /* Grabbing hand cursor */
        border: 1px solid #000000;
      }
    }
  }
}
