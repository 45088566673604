.date-time-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  width: fit-content;
  min-width: 150px !important;
  
  span {
    overflow: visible !important;
    text-overflow: initial !important;
    width: 75px;
  }
}
