.modal-content.custom-tag-modal-container {
  border-radius: 20px;
  width: 350px;
  z-index: 11;
  padding: 6px;

  .modal-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 8px 16px;
    cursor: initial;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    row-gap: 15px;
    cursor: initial;

    .tag-preview-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 10px;

      .special-tag {
        white-space: nowrap;
      }
    }


    .color-preview-container {
      display: grid;
      grid-template-columns: 35px 2fr;
      grid-template-rows: .25fr 1fr 1fr;
      grid-template-areas:
      "label label"
      "color input"
      "copy copy";

      label {
        grid-area: label;
      }

      .color-preview {
        grid-area: color;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        align-self: center;
      }

      .tag-color-input {
        grid-area: input;

        &.has-error {
          color: rgb(248 113 113);
        }
      }

      p {
        grid-area: copy;
        line-height: .825rem;
        align-self: flex-end;
      }
    }

    .default-color-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 6px;
      padding-bottom: 6px;

      .default-color-selector {
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: 100%;
        column-gap: 15px;

        .color-selector {
          height: 20px;
          width: 20px;
          border-radius: 2px;
          color: transparent;
          font-size: 5px;
        }
      }
    }

    label {
      font-size: 0.75rem;
    }

    .tag-name-input,
    .tag-color-input {
      width: 100%;

      div {
        margin-top: 0;
      }

      input[type='text'] {
        height: 35px;
      }

      .error-text {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  .modal-footer {
    justify-content: flex-end;
    padding: 22px 16px 16px 16px;

    .modal-confirm-button, {
      color: white;
      letter-spacing: 1.41818px;
      font-size: 0.625rem;
      font-weight: 600;
      border-radius: 3px;
      line-height: 14px;
      text-align: center;
      width: 75px;
      height: 28px;
      background: #05944F;

      &:disabled {
        opacity: 0.3;
        cursor: initial;
      }
    }
  }
}
