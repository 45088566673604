.menu-section-inline-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  svg {
    align-self: center;
  }
}
