.day-hour-picker {
  .day-hour-picker-label {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    color: #505159;
  }

  .day-picker {
    font-size: 0.8125rem;
    letter-spacing: 0.5px;
  }

  .time-picker {
    width: 100px;

    input {
      height: 40px;
    }
  }
}
