.size-price-cell-container {
  align-items: center;
  min-width: 100px;
  font-size: .875rem;
  font-weight: 300;
  letter-spacing: .5px;
  padding-right: 10px;

  .size-price-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: inherit;
    align-items: flex-start;
  }

  .size-label {
    margin-right: 3px;
    width: initial;
  }

  .size-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    width: initial;
  }
}
