.menu-item-wizard-container {
  border-left: 1px solid #efefef;

  .menu-item-wizard-header {
    &.menu-item,
    &.drink-item {
      width: 100%;
      min-height: 290px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 165px auto;
      grid-template-areas:
        'close logo .'
        '. content .';

      svg {
        margin-top: 40px;
        margin-left: 39px;
        grid-area: close;
      }

      img {
        grid-area: logo;
        margin-top: 30px;
        align-self: center;
        justify-self: center;
      }

      .menu-item-wizard-header-content {
        grid-area: content;
        align-self: center;
        justify-self: center;

        h1 {
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 24px;
          text-align: center;
          letter-spacing: 3px;
          text-transform: uppercase;

          color: #ffffff;
          margin-bottom: 12px;
        }
      }
    }

    &.menu-item {
      background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url('../../../../assets/images/MenuItemHeader.png');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.drink-item {
      background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url('../../../../assets/images/DrinkWizardHeader.jpg');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .menu-item-wizard-content-required {
    @media (max-width: $screen-xl) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    padding: 35px 0;
    width: 90%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: repeat(auto-fill, auto);
    grid-template-areas:
      'copy copy'
      'name description'
      'price description'
      'price calories'
      'photo photo'
      'buttons .';
    row-gap: 2em;

    .menu-and-section-selection {
      grid-area: copy;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      &-copy {
        font-weight: 400;
        font-size: 0.725rem;
        line-height: 22px;
        letter-spacing: 0.4px;
        color: #6c6c6c;
      }
    }

    .menu-item-wizard-name-input,
    .menu-item-wizard-description-input {
      width: 100%;
      max-width: 300px;
      grid-area: name;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }
    }

    .menu-item-wizard-name-input {
      width: 100%;
      max-width: 300px;
      grid-area: name;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;

        &::after {
          content: '*';
          color: #a73333;
          margin-left: -3px;
        }
      }

      input[type='text'] {
        height: 35px;
      }

      .error-text {
        font-size: 0.75rem;
      }
    }

    .menu-item-wizard-size-price-container {
      align-self: center;
      width: 100%;
      grid-area: price;
      display: flex;
      flex-direction: column;

      .menu-item-wizard-size-price-label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      .menu-item-wizard-size-price-label::after {
        content: '*';
        color: #a73333;
        margin-left: -3px;
      }

      .size-price-check-box-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        .size-price-check-box-copy {
          font-weight: 400;
          font-size: 0.725rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
        }
      }

      &.is-prix-fixe {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .menu-item-wizard-description-input {
      width: 100%;
      max-width: 500px;
      grid-area: description;

      textarea {
        height: 132px;
      }
    }

    .menu-item-wizard-image-container {
      grid-area: photo;

      .menu-item-wizard-image-label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      .photo-prompt {
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.4px;
        color: #6c6c6c;
        max-width: 700px;
        margin-bottom: 12px;
      }
    }

    .menu-item-wizard-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      grid-area: buttons;
      flex-wrap: wrap;

      .menu-item-wizard-cancel-button {
        background: #a73333;
        margin-right: 23px;
      }

      .menu-item-wizard-next-button {
        background: #05944f;
      }

      .menu-item-wizard-cancel-button,
      .menu-item-wizard-next-button {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 1.41818px;
        width: 119px;
        height: 35px;
        border-radius: 3px;
      }
    }
  }

  .menu-item-wizard-content-optional {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 35px 0;
    width: 90%;
  }

  .special-tags-container {
    label {
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      font-weight: 700;
      margin-bottom: 7px;
    }
    margin-bottom: 10px;
  }

  .optional-value-label {
    font-size: 0.8125rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    font-weight: 700;
  }

  .menu-item-wizard-calories-input {
    width: 100%;
    max-width: 254px;

    label {
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      font-weight: 700;
    }

    input[type='text'] {
      height: 35px;
    }

    .error-text {
      font-size: 0.75rem;
    }
  }

  .media-container {
    display: flex;
    flex-direction: row;
    column-gap: 35px;
  }

  .button.select-media-button {
    width: 175px;
    height: 150px;
    background: #fafafc;
    border: 1px solid #979797;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    border-radius: 0;
    font-size: 11px;
    line-height: 13px;
    color: #707982;

    .media-upload-icon {
      margin-bottom: 6px;
    }
  }

  .menu-item-wizard-back-button {
    background: #7e7e7e;
    margin-right: 23px;
  }

  .menu-item-wizard-submit-button {
    background: #21377a;
  }

  .menu-item-wizard-back-button,
  .menu-item-wizard-submit-button {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 1.41818px;
    width: 119px;
    height: 35px;
    border-radius: 3px;
  }

  .optional-value-button {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 0.5px;
    height: 35px;
    width: fit-content;
    border-radius: 3px;
    background: #05944f;
    padding: 9px;
  }

  // mobile-version
  @media (max-width: 768px) {
    border-left: none;

    .menu-item-wizard-header {
      &.menu-item,
      &.drink-item {
        width: 100%;
        min-height: 130px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 50px auto;
        grid-template-areas:
          'close logo .'
          '. content .';

        svg {
          margin-top: 14px;
          margin-left: 18px;
          grid-area: close;
          width: 7px;
          height: 12px;
          color: white;
        }

        img {
          grid-area: logo;
          margin-top: 27px;
          align-self: center;
          justify-self: center;
          width: 50px;
          height: 50px;
        }

        .menu-item-wizard-header-content {
          grid-area: content;
          align-self: flex-start;
          margin-top: 10px;

          h1 {
            font-weight: 700;
            font-size: 12px;
            line-height: normal;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #ffffff;
            margin-bottom: 10px;
            margin-top: 10px;
            white-space: nowrap;
          }
        }
      }
    }

    .menu-item-wizard-content-required {
      @media (max-width: $screen-xl) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      padding: 25px 0;
      margin-left: 10px;
      width: 90%;
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-template-rows: repeat(auto-fill, auto);
      grid-template-areas:
        'copy copy'
        'name description'
        'price description'
        'price calories'
        'photo photo'
        'buttons .';
      row-gap: 1.5rem;

      .menu-and-section-selection {
        grid-area: copy;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }

        &-copy {
          font-weight: 400;
          font-size: 0.725rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
        }
      }

      .menu-item-wizard-name-input,
      .menu-item-wizard-description-input,
      .menu-item-wizard-calories-input {
        width: 100%;
        width: 293px !important;
        grid-area: name;

        label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }

        input[type='text'] {
          height: 35px;
        }

        .error-text {
          font-size: 0.75rem;
        }
      }

      .menu-item-wizard-description-input {
        width: 100%;
        max-width: 500px;
        grid-area: description;

        textarea {
          height: 161px;
        }
      }

      .menu-item-wizard-image-container {
        grid-area: photo;

        .menu-item-wizard-image-label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }

        .photo-prompt {
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
          max-width: 700px;
          margin-bottom: 12px;
        }

        .media-container {
          display: flex;
          flex-direction: row;
          column-gap: 35px;
        }

        .select-media-button {
          width: 121px;
          height: 160px;
        }
      }

      .menu-item-wizard-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        grid-area: buttons;
        flex-wrap: wrap;

        .menu-item-wizard-cancel-button {
          background: #a73333;
          margin-right: 23px;
        }

        .menu-item-wizard-next-button {
          background: #05944f;
        }

        .menu-item-wizard-cancel-button,
        .menu-item-wizard-next-button {
          border-radius: 6px;
        }
      }
    }

    .menu-item-wizard-content-optional {
      padding: 25px 0;
      margin-left: 15px;
    }

    .menu-item-wizard-back-button {
      background: #a73333;
      margin-right: 23px;
    }

    .menu-item-wizard-submit-button {
      background: #05944f;
    }

    .menu-item-wizard-back-button,
    .menu-item-wizard-submit-button {
      border-radius: 5px;
      margin-top: -20px;
    }

    .optional-value-button {
      border-radius: 5px;
      margin-top: 15px;
    }
  }
}
