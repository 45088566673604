.modal-content.video-upload-modal {
  border-radius: 5px;
  width: 910px;
  padding: 21px 63px;
  cursor: initial;

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 10px 0 10px;
  }

  .modal-body {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "video-label thumbnail-label"
    "video-copy thumbnail-copy"
    "video-input thumbnail-input";
    column-gap: 82px;
    row-gap: 7px;

    .video-upload-label {
      grid-area: video-label;
    }

    .thumbnail-upload-label {
      grid-area: thumbnail-label;
    }

    .thumbnail-upload-label,
    .video-upload-label {
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      font-weight: 700;
    }

    .video-upload-prompt {
      grid-area: video-copy;
      max-width: 271px;
    }

    .thumbnail-upload-prompt {
      grid-area: thumbnail-copy;
    }

    .thumbnail-upload-prompt,
    .video-upload-prompt {
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.4px;
      color: #6C6C6C;
      max-width: 700px;
    }

    .video-upload-input {
      grid-area: video-input;
      margin-top: 8px;
    }

    .thumbnail-upload-input {
      grid-area: thumbnail-input;
      margin-top: 8px;
    }

    .is-disabled {
      opacity: 25%;
      pointer-events: none;
    }
  }

  .error-text {
    padding: 0 10px;
  }

  .modal-cancel-button,
  .modal-confirm-button {
    color: white;
    letter-spacing: 1.41818px;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 3px;
    line-height: 14px;
    text-align: center;
    width: 119px;
    height: 35px;
  }

  .modal-cancel-button {
    background-color: #a73333;
    margin-right: 12px;
  }

  .modal-confirm-button {
    background: #05944f;
  }

  .select-media-button {
    width: 150px;
    height: 153px;
    background: #FAFAFC;
    border: 1px solid #979797;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    border-radius: 0;
    font-size: 11px;
    line-height: 13px;
    color: #707982;

    .media-upload-icon {
      margin-bottom: 6px;
    }
  }
}
