.modal-content.menu-section-modal {
  border-radius: 20px;
  width: 522px;
  line-height: 22px;
  padding: 25px 50px;

  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;
  }

  .modal-body {
    .menu-section-input-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 20px;
      width: 100%;

      .menu-section-input {
        width: 185px;
      }

      .menu-section-input,
      .menu-section-area {
        input {
          height: 35px;
        }

        label {
          font-weight: 500;
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 1.1375px;
          color: #6c6c6c;
        }
      }

      .menu-section-area {
        width: 100%;
        label + div + div {
          margin-top: 0;
        }
      }
    }

    .error-text {
      font-size: 0.75rem;
    }
  }

  .modal-footer {
    justify-content: flex-end;
    padding: 15px 0 0 0;
  }

  .modal-title {
    letter-spacing: 1.1375px;
  }

  .modal-cancel-button,
  .modal-confirm-button {
    color: white;
    letter-spacing: 1.41818px;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 3px;
    line-height: 14px;
    text-align: center;
    width: 119px;
    height: 35px;
  }

  .modal-cancel-button {
    background-color: #a73333;
    margin-right: 12px;
  }

  .modal-confirm-button {
    background: #05944f;
  }

  @media (max-width: 768px) {
    width: 75%;
    padding: 15px 20px;
    border-radius: 0px;

    .modal-body {
      .menu-section-input-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 15px;
        width: 100%;

        .menu-section-input {
          width: 145px;
        }

        .menu-section-area {
          textarea {
            height: 135px;
          }
        }
      }
    }

    .modal-footer {
      .modal-cancel-button,
      .modal-confirm-button {
        border-radius: 7px;
      }

      .modal-cancel-button {
        margin-right: 20px;
      }
    }
  }
}
