@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url(../../src/assets/fonts/Lato-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
