.mobile-burger-sidebar {
  position: fixed;
  top: 38px;
  right: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 106px;
  display: none;
  transition: transform 0.3s ease;
  border-top: 0.05px solid #000000;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }

  &.visible {
    display: block;
    transform: translateX(0);
  }

  .sidebar-link-container {
    box-sizing: border-box;
    text-align: left;

    .sidebar-link {
      & > a {
        font-family: Lato;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.5px;
        display: block;
        text-decoration: none;
        color: #000;
        border: 0.05px solid #000000;
        height: 25px;
        padding-left: 2px;
        padding-top: 3px;
        border-top: 0;

        &:hover {
          background-color: #f7f7f7;
        }
      }
      &.selected {
        background-color: #f7f7f7;
      }
    }
  }
}
