.modifier-group-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 6px;
  background: #ffffff;
  border-bottom: 1px solid #979797;
  padding: 6px;

  &:last-child {
    border-bottom: none;
  }

  &.is-dragging {
    background-color: #f5f5f5;  /* Lighter background */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);  /* Box shadow for depth */
    opacity: 0.9;  /* Slightly transparent */
    cursor: grabbing;  /* Grabbing hand cursor */
    border: 1px solid #000000;
  }

  .modifier-group-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    column-gap: 12px;
    letter-spacing: 0.466667px;
    font-size: 0.875rem;

    .draggable-icon {
      cursor: move;
    }
  }

  .modifier-group-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 20px;
    flex-wrap: nowrap;

    button {
      align-self: flex-end;
      background: transparent;
      border: none;
      width: fit-content;
      height: initial;
      border-radius: 0;
    }
  }
}
