.entity-selector-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  max-height: 500px;
  overflow: scroll;
  row-gap: 10px;

  .entity-selection {
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
    margin: 5px;

    .entity-selector {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 6px;

      &.hidden-entity {
        color: #c0c0c0;
      }

      input[type='checkbox'] {
        margin: 0;
      }

      .entity-name {
        word-break: break-word;
      }

      .entity-price,
      .entity-name {
        text-align: left;
        line-height: 12px;
      }
    }
  }
}
