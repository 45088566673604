.image-upload-container {
  width: 265px;
  height: 139px;
  background: #FAFAFC;
  border: 1px solid #979797;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-size: 11px;
  line-height: 13px;
  color: #707982;

  .image-upload-icon {
    margin-bottom: 6px;
  }

  input[type=file] {
    display: none;
  }
}

.image-preview-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  .image-preview-image {
    width: 150px;
    height: inherit;
    margin-right: 10px;
    object-fit: cover;
  }
}
