.menu-wizard-container {
  background-color: #fbf9f9;

  .menu-wizard-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../../../assets/images/BackgroundBowls.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 290px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 165px auto;
    grid-template-areas:
      'close logo .'
      '. content .';

    &-close-icon {
      margin-top: 40px;
      margin-left: 39px;
      grid-area: close;
    }

    img {
      grid-area: logo;
      margin-top: 30px;
      align-self: center;
      justify-self: center;
    }

    &-content {
      grid-area: content;
      align-self: center;
      justify-self: center;

      .menu-wizard-header-title {
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  .menu-wizard-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    justify-content: center;
    max-width: 700px;
    padding: 30px 60px;
    width: 100%;

    .menu-wizard-name-input {
      input {
        max-width: 336px;
        width: 100%;
      }

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      input,
      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }
    }

    .menu-section-label {
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      font-weight: 700;
    }

    .menu-wizard-text-area {
      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }
    }

    .menu-wizard-next-button {
      background: #05944f;
      border-radius: 3px;
      font-size: 0.75rem;
      font-weight: 600;
      grid-area: button;
      height: 35px;
      letter-spacing: 1.41818px;
      line-height: 14px;
      text-align: center;
      width: 100%;
      color: white;

      &:disabled {
        background: #f7fff3;
        color: #222222;
      }
    }
  }

  .add-days-hours-button {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 17px;
    color: #505159;
    margin-top: 20px;
  }

  .menu-wizard-checkbox {
    label {
      font-size: 0.875rem;
      letter-spacing: 0.466667px;
      color: #6c6c6c;
    }
  }

  .menu-section-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    margin-top: 6px;

    span {
      font-size: 0.875rem;
      line-height: 22px;
      letter-spacing: 0.466667px;
      color: #6c6c6c;
    }
  }

  .menu-wizard-text-area {
    div:nth-child(2) {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 22px;
      letter-spacing: 0.466667px;
      color: #6c6c6c;
    }
  }

  .menu-wizard-back-button {
    background: #9a9a9a;
  }

  .menu-wizard-submit-button {
    background: #05944f;

    &:disabled {
      background: #f7fff3;
      color: #222222;
    }
  }

  .day-hour-picker {
    width: 694px;
    padding: 54px 35px 54px 57px;
  }

  .menu-wizard-back-button,
  .menu-wizard-submit-button {
    width: 167px;
    height: 35px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 1.41818px;
  }

  @media (max-width: 768px) {
    .menu-wizard-header {
      height: 130px;
      width: 100%;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 50px auto;
      grid-template-areas:
        'close logo .'
        '. content .';

      &-close-icon {
        margin-top: 14px;
        margin-left: 18px;
        grid-area: close;
        width: 7px;
        height: 12px;
        color: white;
      }

      img {
        grid-area: logo;
        margin-top: 27px;
        align-self: center;
        justify-self: center;
        width: 50px;
        height: 50px;
      }

      &-content {
        grid-area: content;
        align-self: flex-start;
        margin-top: 10px;

        .menu-wizard-header-title {
          font-weight: 700;
          font-size: 12px;
          line-height: normal;
          text-align: center;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 10px;
          margin-top: 10px;
          white-space: nowrap;
        }
      }
    }
    .menu-wizard-content-container {
      padding: 10px 10px;
      width: 100%;
      margin-left: 15px;

      .menu-wizard-name-input {
        input {
          width: 232px;
        }
      }

      .menu-wizard-text-area {
        label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }
        margin-right: 10px;
        margin-bottom: 40px;
      }

      .menu-wizard-next-button {
        background: #05944f;
        border-radius: 3px;
        font-size: 0.75rem;
        font-weight: 600;
        grid-area: button;
        height: 35px;
        letter-spacing: 1.41818px;
        line-height: 14px;
        text-align: center;
        width: 100%;
        color: white;

        &:disabled {
          background: #f7fff3;
          color: #222222;
        }
      }
    }

    .menu-wizard-cancel-button {
      background-color: #a73333;
      grid-area: button;
    }

    .menu-wizard-back-button,
    .menu-wizard-cancel-button,
    .menu-wizard-submit-button {
      width: 119px;
      height: 35px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 1.41818px;
    }

    .day-hour-picker {
      display: flex;
      flex-direction: column;
      width: 375px;
      height: 100%;
      padding: 10px 10px 10px 10px;
      overflow: visible;
    }

    .menu-wizard-submit-button {
      margin-left: 40px;
    }
  }

  .menu-wizard-cancel-button {
    background-color: #a73333;
    grid-area: button;
  }
}
