.modifier-wizard-container {
  border-left: 1px solid #efefef;

  .modifier-wizard-header {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      url('../../../../assets/images/SidesHeader.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 235px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 165px auto;
    grid-template-areas:
      'close logo .'
      '. content .';

    & svg {
      margin-top: 40px;
      margin-left: 39px;
      grid-area: close;
    }

    img {
      grid-area: logo;
      margin-top: 30px;
      align-self: center;
      justify-self: center;
    }

    &-content {
      grid-area: content;
      align-self: center;
      justify-self: center;

      h1 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 12px;
      }
    }
  }

  .modifier-wizard-content {
    @media (max-width: $screen-xl) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    padding: 35px 0;
    width: 90%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'name description'
      'price description'
      'buttons .'
      '. .';

    label {
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      font-weight: 700;
    }

    input {
      height: 35px;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    .error-text {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    .modifier-wizard-name-input {
      width: 100%;
      max-width: 293px;
      grid-area: name;
    }

    .modifier-wizard-price-input {
      width: 97px;
      grid-area: price;
      overflow: visible;
      white-space: nowrap;
    }

    .modifier-wizard-description-input {
      width: 100%;
      max-width: 500px;
      grid-area: description;

      textarea {
        height: 132px;
      }
    }

    .modifier-wizard-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      grid-area: buttons;
      flex-wrap: wrap;
      padding-top: 15px;

      .modifier-wizard-cancel-button {
        background: #a73333;
        margin-right: 23px;
      }

      .modifier-wizard-submit-button {
        background: #05944f;
      }

      .modifier-wizard-cancel-button,
      .modifier-wizard-submit-button {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 1.41818px;
        width: 119px;
        height: 35px;
        border-radius: 3px;
      }
    }
  }
}

// mobile version
.mobile-modifier-wizard-container {
  .modifier-wizard-header {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      url('../../../../assets/images/SidesHeader.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 70px auto;
    grid-template-areas:
      'close logo .'
      '. content .';

    & svg {
      margin-top: 14px;
      margin-left: 18px;
      grid-area: close;
      width: 7px;
      height: 12px;
      color: white;
    }

    img {
      grid-area: logo;
      margin-top: 17px;
      align-self: center;
      justify-self: center;
      width: 50px;
      height: 50px;
    }

    &-content {
      grid-area: content;
      align-self: flex-start;
      margin-top: 5px;

      h1 {
        font-weight: 700;
        font-size: 12px;
        line-height: normal;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 12px;
        white-space: nowrap;
      }
    }
  }

  .modifier-wizard-content {
    @media (max-width: $screen-sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    padding: 20px 0;
    width: 90%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'name description'
      'price description'
      'buttons .'
      '. .';

    label {
      font-size: 0.813rem;
      line-height: 22px;
      letter-spacing: 1.138px;
      font-weight: 600;
    }

    input {
      height: 35px;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    .error-text {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #a73333;
    }

    .modifier-wizard-name-input {
      width: 100%;
      max-width: 293px;
      grid-area: name;
    }

    .modifier-wizard-price-input {
      width: 97px;
      grid-area: price;
      overflow: visible;
      white-space: nowrap;
      margin-top: 20px;
    }

    .modifier-wizard-description-input {
      width: 100%;
      width: 293px;
      height: 161px;
      grid-area: description;
      margin-top: 20px;

      textarea {
        height: 161px;
      }
    }

    .modifier-wizard-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      grid-area: buttons;
      flex-wrap: wrap;
      padding-top: 15px;
      margin-top: 35px;

      .modifier-wizard-cancel-button {
        background: #a73333;
        margin-right: 23px;
      }

      .modifier-wizard-submit-button {
        background: #05944f;
      }

      .modifier-wizard-cancel-button,
      .modifier-wizard-submit-button {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 1.41818px;
        width: 119px;
        height: 35px;
        border-radius: 7px;
      }
    }
  }
}
