.context-menu {
  width: 88px;
  background-color: #ffffff;
  color: black;
  border: 0.25px solid #000000;
  border-radius: 2px;
  text-align: center;
  z-index: 10;
  /* Position the tooltip */
  position: fixed;

  & li {
    border-bottom: 0.25px solid #000000;
    z-index: 10;

    a {
      display: grid;
      grid-template-columns: 16px auto;
      column-gap: 6px;
      align-items: center;
      width: 100%;
      height: 25px;
      padding: 0 6px;

      font-weight: 300;
      font-size: 0.75rem;
      line-height: 14px;
      letter-spacing: 0.625px;
      text-transform: initial;

      span {
        margin: 0;
        justify-self: flex-start;
      }

      svg {
        justify-self: center;
      }

      &:hover {
        background-color: black;
        color: white;

        & svg * {
          fill: white;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
