.email-manager-top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;
  height: 90px;
  width: 100%;

  .button.download-emails-button {
    align-self: center;
    margin-left: auto;
    font-size: .875rem;
    width: 182px;
    height: 37px;
    background: #14A68C;
  }
}
