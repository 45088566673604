.published-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;

  .is-published {
    width: 12px;
    height: 12px;

    background: #217A22;
    border-radius: 50%;
  }

  .not-published {
    width: 12px;
    height: 12px;

    background: #A73333;
    border-radius: 50%;
  }
}
