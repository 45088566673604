.item-wizard-menu-selector {
  width: 90%;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #000000;
  opacity: 0.9;
  height: fit-content;

  .dropdown-container {
    label {
      .select-wrapper {
        margin-top: 0.5rem;

        select {
          height: 35px;
          padding-top: 0;
          padding-bottom: 0;
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 768px) {
    gap: 80px;

    label {
      .select-wrapper {
        margin-top: 0.5rem;

        select {
          height: 35px;
          padding-top: 0;
          padding-bottom: 0;
          text-align: left;
          width: 137px;
        }
      }
    }
  }
}
