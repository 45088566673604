.inline-input-container {
  display: flex;
  flex-direction: column;
  
  input {
    background: #FAFAFC;
    border: 1px solid #979797;
    box-sizing: border-box;
    padding: 12px 8px;
    width: 154px;
    height: 35px;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: #000000;
  }

  .inline-input-error {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #A73333;
  }
}
