.price-cell-container {
  align-items: center;
  min-width: 80px;

  .price-value {
    font-size: .875rem;
    font-weight: 300;
    letter-spacing: .5px;
  }
}
