.mobile-promotion-item-table-container {
  width: 100%;
  .ag-mobile-theme-alpine {
    @include ag-theme-alpine(
      (
        // use theme parameters where possible
        header-background-color: white,
        odd-row-background-color: white,
        row-hover-color: white,
        column-hover-color: white,
        header-foreground-color: black,
        row-height: 60px,
        header-height: 40px,
        font-size: 0.75rem,
        borders: false
      )
    );

    .no-border {
      margin-left: 0;
    }
    .no-border.ag-cell:focus {
      border: none !important;
      outline: none;
    }

    .ag-cell {
      padding: 0 !important;
      display: flex;

      .custom-cell-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        word-break: break-word;
        & span {
          font-weight: 400;
          font-size: 12px;
          overflow: auto;
          white-space: normal;
          text-overflow: ellipsis;
          word-break: break-word;
          width: inherit;
        }
      }
    }

    .hidden-cell-value {
      opacity: 0.2;
    }

    .ag-row-selected,
    .ag-row-hover {
      background: rgba(196, 196, 196, 0.2);
      height: 100px;

      & .context-menu-action-container {
        visibility: visible;
      }
    }

    .ag-ltr .ag-cell-focus {
      border: none;
      background: rgba(135, 226, 255, 0.2);
    }

    .ag-root-wrapper {
      font-size: 0.6875rem;
    }

    .name-header {
      border-bottom: 0.5px solid #c7c7cd !important;

      .ag-header-cell-label {
        margin-left: 67px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    .custom-header {
      padding: 0 !important;
      border-bottom: 0.5px solid #c7c7cd !important;
    }

    .price-sell-container .custom-header,
    .name-header {
      font-weight: 700 !important;
      font-size: 12px;
      letter-spacing: 1 !important;
    }

    .promotion-name-cell {
      & span {
        font-weight: 400;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        width: inherit;
      }
    }
  }

  .item-row {
    border-bottom: 0.5px solid #c7c7cd !important;
  }
}
