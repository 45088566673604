.media-library-top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;
  height: 90px;
  width: 100%;

  .media-type-dropdown {
    font-size: 0.75rem;
    margin: 10px 0;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #6c6c6c;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .select-wrapper {
      width: 137px;

      select {
        text-align: left;
      }
    }
  }
}

.mobile-media-library-top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  width: 100%;

  .mobile-media-library-top-bar-title {
    font-size: 15px;
    font-weight: 700;
    font-family: Lato;
    letter-spacing: 0.5px;
    line-height: 22px;
    margin-left: 10px;
  }

  .media-type-dropdown {
    font-size: 0.75rem;
    margin: 10px 0;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #6c6c6c;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;

    .select-wrapper {
      width: fit-content;
      border: 2px solid #ddd;

      select {
        width: 92px;
        height: 25px;
        text-align: left;
        border: 1px #ddd;
        padding-top: 5px;
        padding-bottom: 5px;
        border-right: 5px solid transparent;
        font-size: 10px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #6c6c6c;
      }
    }
  }
}
