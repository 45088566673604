.reservation-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  border-left: 1px solid #efefef;
  background-color: #fbf9f9;

  .reservation-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url('../../../../assets/images/RestaurantWizardHeader.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 235px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 165px auto;
    grid-template-areas:
      'close logo .'
      '. content .';

    &-close-icon {
      display: none;
      pointer-events: none;
      margin-top: 40px;
      margin-left: 39px;
      grid-area: close;
    }

    img {
      grid-area: logo;
      margin-top: 30px;
      align-self: center;
      justify-self: center;
    }

    &-content {
      grid-area: content;
      align-self: center;
      justify-self: center;
      h1 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 12px;
      }
    }
  }

  .reservation-order-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    background-color: white;
    border-radius: 10px;
    padding: 30px 60px;

    .reservation-order-submit-button {
      grid-area: next;
      justify-self: flex-end;
      background: #05944f;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 1.41818px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 14px;
      height: 35px;
      width: 167px;
      margin-top: 10px;
    }

    .reservation-order-page {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 20px;
      grid-template-areas:
        'social social'
        'back next';
      justify-content: space-between;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      input,
      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .reservation-order-inputs {
        grid-area: social;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;

        .reservation-order-input {
          width: 100%;
          input {
            max-width: 576px;
            width: 100%;
          }
        }
        .link-prompt {
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
          margin-top: 3px;
        }
      }
    }
  }
}
