.modal-content.selector-modal {
  border-radius: 20px;
  width: 522px;
  line-height: 22px;
  padding: 25px 50px;

  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;

    & svg {
      align-self: flex-end;
    }
  }

  .modal-footer {
    justify-content: flex-start;
    padding: 15px 0 0 0;
  }

  .modal-title {
    letter-spacing: 1.1375px;
  }

  .selector-container {
    border-top: 1px solid #dedede;
    padding-top: 20px;
  }

  .modal-message {
    font-weight: 400;
    letter-spacing: 0.466667px;
    font-size: 0.875rem;
  }

  .modal-message {
    color: #6c6c6c;
    margin-bottom: 15px;
  }

  .selection-error {
    color: #a73333;
    font-size: 0.625rem;
    margin-bottom: 5px;
    align-self: center;
  }

  .selector-modal {
    margin-bottom: 15px;
  }

  .modal-cancel-button,
  .modal-confirm-button,
  .modal-create-button {
    color: white;
    letter-spacing: 1.41818px;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 14px;
    border-radius: 3px;
  }

  .modal-cancel-button {
    width: 119px;
    height: 35px;
    background-color: #a73333;
    margin-right: 12px;
  }

  .modal-confirm-button {
    width: 236px;
    height: 35px;
    background: #05944f;
  }

  .modal-create-button {
    width: 169px;
    height: 35px;
    background-color: #21377a;
  }

  a {
    color: red;
    text-decoration: underline;
    padding-left: 3px;
    padding-right: 3px;
  }

  @media (max-width: 768px) {
    width: 80%;
    padding: 5px 20px;
    border-radius: 0px;

    .modal-header {
      display: flex;
      flex-direction: column;
      padding: 10px 10px 0 10px;

      & svg {
        align-self: flex-end;
        width: 10px;
        margin-right: -10px;
      }
    }
    .modal-footer {
      justify-content: space-between;
    }

    .entity-selector {
      margin-bottom: 10px;
    }

    .modal-message {
      color: #6c6c6c;
      margin-bottom: 10px;
      margin-top: -5px;
    }

    .modal-cancel-button,
    .modal-confirm-button,
    .modal-create-button {
      width: 96px;
      margin-bottom: 10px;
      margin-left: 5px;
      border-radius: 5px;
    }

    .modal-cancel-button {
      margin-right: 5px;
    }

    .modal-confirm-button {
      margin-right: 18px;
    }
  }
}
