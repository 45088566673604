.press-page-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  border-left: 1px solid #efefef;
  background-color: #fbf9f9;

  .press-page-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../../../../assets/images/RestaurantWizardHeader.png");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 270px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 165px auto;
    grid-template-areas:
      "close logo ."
      ". content .";

    &-close-icon {
      display: none;
    }

    img {
      grid-area: logo;
      margin-top: 30px;
      align-self: center;
      justify-self: center;
    }

    &-content {
      grid-area: content;
      align-self: center;
      justify-self: center;

      .press-page-header-title {
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  .press-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    background-color: white;
    border-radius: 10px;
    padding: 30px 60px;

    .restaurant-press-page {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .restaurant-press-page-press-title {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
        margin-block-end: 0;
      }

      .press-page-photos {
        grid-area: about;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .media-container {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          .multiple-media-uploader-container {
            .multiple-media-uploader {
              .multiple-media-drag-and-drop {
                column-gap: 7px !important;
              }
            }
          }
        }

        .press-page-label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }

        .press-page-photo-prompt {
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
          max-width: 700px;
          margin-bottom: 2px;
        }

        textarea {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.5px;
          height: 132px;
        }

        .button.select-media-button {
          width: 265px;
          height: 165px;
          background: #fafafc;
          border: 1px solid #979797;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          border-radius: 0;
          font-size: 11px;
          line-height: 13px;
          color: #707982;

          .media-upload-icon {
            margin-bottom: 6px;
          }
        }
      }

      .press-articles-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .press-page-prompt {
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.4px;
        color: #6c6c6c;
        max-width: 700px;
        margin-bottom: 12px;
      }
    }

    .press-page-buttons {
      grid-area: button;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .press-page-delete-button {
        grid-area: back;
        background: #9a9a9a;
        border-radius: 3px;
        text-align: center;
        letter-spacing: 1.41818px;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 14px;
        height: 35px;
        width: 167px;
        margin-top: 10px;

        &:disabled {
          cursor: not-allowed;
          opacity: 50%;
          color: white;
        }
      }

      .press-page-submit-button {
        grid-area: submit;
        justify-self: flex-end;
        background: #05944f;
        border-radius: 3px;
        text-align: center;
        letter-spacing: 1.41818px;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 14px;
        height: 35px;
        width: 167px;
        margin-top: 10px;
      }
    }
  }
}
