.modal {
  &-content {
    border-radius: 5px;
    width: 455px;
    line-height: 22px;
  }

  &-header {
    padding: 10px 10px 0 10px;
  }

  &-footer {
    padding: 10px 20px;
  }

  &-title {
    letter-spacing: 1.1375px;
  }

  &-message,
  &-warning {
    font-weight: 400;
    letter-spacing: 0.466667px;
    font-size: 0.875rem;
  }

  &-error {
    color: #a73333;
    font-size: 0.625rem;
    margin-bottom: 5px;
  }

  &-message {
    color: #6c6c6c;
    margin-bottom: 15px;
  }

  &-warning {
    color: #a73333;
    margin-bottom: 18px;
  }

  &-cancel-button,
  &-confirm-button {
    width: 102px;
    height: 35px;
    color: white;
    letter-spacing: 1.41818px;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 3px;
    line-height: 14px;
    text-align: center;
  }

  &-cancel-button {
    background-color: #7e7e7e;
    margin-right: 12px;
  }

  &-confirm-button {
    background-color: #a73333;
  }

  @media (max-width: 767px) {
    &-content {
      width: 75%;
      margin: 0;
      border-radius: 0;
    }

    &-warning {
      color: #a73333;
      margin-bottom: 10px;
    }

    &-cancel-button {
      background-color: #7e7e7e;
      margin-right: 20px;
      border-radius: 7px;
    }

    &-confirm-button {
      background-color: #a73333;
      border-radius: 7px;
    }

    &-footer {
      padding: 10px 10px;
      display: flex;
      justify-content: flex-start;
    }
  }
}
