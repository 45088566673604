.promotion-preview {
  width: 135px;
  min-height: 250px;
  background-color: #FFFFFF;
  color: black;
  border: 0.25px solid #000000;
  text-align: center;
  z-index: 10;
  /* Position the tooltip */
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .phone-preview-container {
    width: 125px;
    height: 240px;
    border: 1px solid #000000;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .modal-preview-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 11;

      .modal-preview-content {
        width: 75%;
        background-color: white;
        -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        padding: 6px 10px;

        &.has-image {
          padding: 0;
          position: relative;
        }

        .modal-preview-header {
          width: 100%;
          margin-bottom: 4px;

          .image-preview-container {
            width: 100%;

            .image-preview-image {
              height: 60px;
              width: 100%;
              margin-right: 0;
              border-radius: 4px 4px 0 0;
            }
          }

          .embed-close-button {
            position: absolute;
            top: 0;
            left: 0;
            margin: 5px 0 0 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .modal-preview-body {
          width: 100%;
          font-size: 6px;
          color: black;
          white-space: pre-wrap;

          &.has-image {
            padding: 0 10px 6px 10px;
          }

          .modal-preview-title {
            font-weight: 500;
            text-align: center;
            font-size: 6.5px;
            margin-bottom: 4px;
          }

          .modal-preview-message {
            font-weight: 300;
            text-align: left;
            font-size: 5px;
            min-height: 20px;
          }
        }
      }
    }

    .drawer-preview-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 11;

      .drawer-preview-content {
        width: 100%;
        background-color: white;
        -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
        border-radius: 6px 6px 0 0;
        padding: 6px 15px;

        &.has-image {
          padding: 0;
          position: relative;
        }

        .drawer-preview-header {
          width: 100%;
          margin-bottom: 4px;

          .image-preview-container {
            width: 100%;

            .image-preview-image {
              height: 60px;
              width: 100%;
              margin-right: 0;
              border-radius: 6px 6px 0 0;
            }
          }

          .embed-close-button {
            position: absolute;
            top: 0;
            left: 0;
            margin: 5px 0 0 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .drawer-preview-body {
          width: 100%;
          font-size: 6px;
          color: black;
          white-space: pre-wrap;

          &.has-image {
            padding: 0 15px 6px 15px;
          }

          .drawer-preview-title {
            font-weight: 500;
            text-align: center;
            font-size: 5.5px;
            margin-bottom: 2px;
          }

          .drawer-preview-message {
            font-weight: 300;
            text-align: left;
            font-size: 5px;
            min-height: 10px;
          }
        }
      }
    }

    .phone-preview-content {
      position: absolute;
      top: 0;

      .phone-preview-header {
        width: 100%;
        border-radius: 10px 10px 0 0;
        background-color: black;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        color: white;
        padding: 6px;

        .phone-preview-restaurant-name {
          font-size: 6px;
        }

        .phone-preview-menu-name {
          font-size: 8.5px;
        }

        .phone-preview-menu-section-name {
          font-size: 5px;
        }
      }

      .phone-preview-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 6px;
        row-gap: 4px;
        overflow: hidden;

        p {
          font-size: 7px;
        }

        .embed-preview-container {
          width: 100%;
          position: relative;

          .image-preview-container {
            width: 100%;

            .image-preview-image {
              height: 69px;
              width: 100%;
              margin-right: 0;
            }
          }

          .embed-preview-placeholder {
            height: 96px;
            width: 100%;
            background: #FAFAFC;
            border: 1px solid #979797;
            color: #707982;
            font-size: 11px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .embed-close-button {
            position: absolute;
            top: 0;
            left: 0;
            margin: 5px 0 0 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .phone-preview-items {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          row-gap: 10px;

          .phone-preview-item {
            width: 52px;
            height: 85px;
            background-color: black;
            border-radius: 5px;
          }
        }
      }
    }

  }
}
