.about-page-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  border-left: 1px solid #efefef;
  background-color: #fbf9f9;

  .about-page-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../../../../assets/images/RestaurantWizardHeader.png");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 270px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 165px auto;
    grid-template-areas:
      "close logo ."
      ". content .";

    &-close-icon {
      margin-top: 40px;
      margin-left: 39px;
      grid-area: close;
    }

    img {
      grid-area: logo;
      margin-top: 30px;
      align-self: center;
      justify-self: center;
    }

    &-content {
      grid-area: content;
      align-self: center;
      justify-self: center;

      .about-page-header-title {
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  .about-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    background-color: white;
    border-radius: 10px;
    padding: 30px 60px 20px 60px;

    .about-page-delete-button {
      grid-area: back;
      background: #9a9a9a;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 1.41818px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 14px;
      height: 35px;
      width: 167px;
      margin-top: 10px;

      &:disabled {
        cursor: not-allowed;
        opacity: 50%;
        color: white;
      }
    }

    .about-page-submit-button {
      grid-area: submit;
      background: #05944f;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 1.41818px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 14px;
      height: 35px;
     width: 100%;
      margin-top: 10px;
    }

    .restaurant-about-page {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 25px;
      grid-template-areas:
        "name name"
        "about about"
        "description description"
        "team team"
        "create-team-button create-team-button"
        "team-name title"
        "team-description team-photo"
        "new-team-member new-team-member"
        "button button";
      justify-content: space-between;

      label {
        font-size: 0.8125rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        font-weight: 700;
      }

      input,
      select {
        height: 35px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
      }

      .about-page-name-input {
        grid-area: name;

        input {
          max-width: 336px;
          width: 100%;
        }
      }

      .about-page-description-input {
        grid-area: description;

        textarea {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.5px;
          height: 321px;
        }
      }

      .restaurant-wizard-about-name-input {
        grid-area: team-name;

        input {
          max-width: 265px;
        }
        label {
          font-weight: 400;
        }
      }

      .restaurant-wizard-title-input {
        grid-area: title;
        justify-self: flex-end;

        input {
          width: 265px !important;
        }
        label {
          font-weight: 400;
        }
      }

      .about-page-create-team-button {
        grid-area: create-team-button;
        height: 20px;
        width: 200px;
        justify-self: flex-start;
        background: transparent;
        color: black;
        letter-spacing: 1.41818px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 14px;
        margin-left: -35px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          margin-right: 5px;
          height: 12px;
        }
      }

      .about-page-button {
        grid-area: button;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: -35px;
      }

      .about-page-about-section-photos {
        grid-area: about;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .media-container {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          .multiple-media-uploader-container {
            .multiple-media-uploader {
              .multiple-media-drag-and-drop {
                column-gap: 7px !important;
              }
            }
          }
        }

        .about-page-about-section-label {
          font-size: 0.8125rem;
          line-height: 22px;
          letter-spacing: 0.9625px;
          font-weight: 700;
        }

        .about-page-photo-prompt {
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
          max-width: 700px;
          margin-bottom: 2px;
        }

        textarea {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.5px;
          height: 132px;
        }

        .button.select-media-button {
          width: 265px;
          height: 165px;
          background: #fafafc;
          border: 1px solid #979797;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          border-radius: 0;
          font-size: 11px;
          line-height: 13px;
          color: #707982;

          .media-upload-icon {
            margin-bottom: 6px;
          }
        }
      }

      .about-page-section-team {
        grid-area: team;

        .about-page-photo-prompt {
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.4px;
          color: #6c6c6c;
          max-width: 700px;
        }
      }

      .restaurant-wizard-about-team-description-input {
        grid-area: team-description;

        textarea {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 22px;
          letter-spacing: 0.5px;
          height: 160px;
          width: 265px;
        }
        label {
          font-weight: 400;
        }
      }

      .restaurant-wizard-about-team-photo {
        grid-area: team-photo;
        justify-self: flex-end;

        label {
          font-weight: 400;
        }

        .about-page-profile-photo-upload {
          .image-upload-container {
            height: 160px;
            margin-top: 7px;
          }
        }
        .new-team-member-container {
          grid-area: new-team-member;
          display: flex;
          flex-direction: column;
          flex-wrap: no-wrap;
        }
      }
    }
  }
}
