.special-tag {
  font-style: normal;
  color: #fff;
  letter-spacing: .25px;
  line-height: 15px;
  text-align: center;
  word-break: keep-all;
  font-size: .688rem;
  font-weight: 400;
  max-height: none;
  min-height: auto;
  padding: 4px 10px;
  font-family: "Helvetica Neue", sans-serif;
  border-radius: 60px;
}
