.restaurant-about-team-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 580px;

  .restaurant-wizard-about-team-description-input {
    width: 100%;
    position: relative;

    .error-text {
      margin-top: 20px;
    }
  }

  .restaurant-wizard-about-name-input {
    flex: 2;
  }
  .restaurant-wizard-title-input {
    flex: 1;
  }

  .restaurant-wizard-about-team-description-input {
    flex: 2;
  }

  .restaurant-wizard-about-team-photo {
    flex: 1;

    .button.select-media-button {
      width: 265px;
      height: 160px;
      background: #fafafc;
      border: 1px solid #979797;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
      border-radius: 0;
      font-size: 11px;
      line-height: 13px;
      color: #707982;
      margin-bottom: 10px;
      margin-top: 10px;

      .media-upload-icon {
        margin-bottom: 6px;
      }
    }

    .image-preview-container {
      width: 265px;
      height: 160px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;

      .image-preview-image {
        width: 160px;
        height: inherit;
        margin-right: 5px;
        object-fit: cover;
      }
      svg {
        height: 12px;
        align-self: flex-end;
      }
    }
  }

  .delete-team-member-button {
    height: 20px;
    width: 200px;
    justify-self: flex-end;
    background: transparent;
    color: black;
    letter-spacing: 1.41818px;
    font-weight: 800;
    font-size: 1rem;
    line-height: 14px;
    margin-bottom: 25px;
    margin-top: 7px;
    display: flex;
    flex-direction: flex-start;
    justify-content: left;
    align-items: center;

    svg {
      margin-right: 5px;
      height: 12px;
    }
  }

  .about-page-add-button {
    height: 20px;
    width: 230px;
    justify-self: flex-start;
    background: transparent;
    color: black;
    letter-spacing: 1.41818px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 14px;
    margin-top: 7px;
    margin-right: 85px;

    display: flex;
    flex-direction: flex-start;
    justify-content: left;
    align-items: center;

    svg {
      margin-right: 5px;
      height: 12px;
    }
  }

  .hide-team-member-button {
    height: 20px;
    width: 200px;
    justify-self: flex-end;
    background: transparent;
    color: black;
    letter-spacing: 1.41818px;
    font-weight: 800;
    font-size: 1rem;
    line-height: 14px;
    margin-top: 7px;

    display: flex;
    flex-direction: flex-start;
    justify-content: left;
    align-items: center;

    svg {
      margin-right: 5px;
      height: 12px;
    }
  }

  .label,
  .error-text {
    font-size: 0.8125rem;
    line-height: 22px;
    letter-spacing: 0.9625px;
    font-weight: 700;
  }

  input,
  select,
  textarea {
    height: 35px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
