.tag-filter-container {
  width: 250px;
  margin-top: 7px;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  row-gap: 7px;
  height: fit-content;
  min-height: 65px;

  .special-tag {
    white-space: nowrap;
  }
}

.tag-input {
  width: 100%;

  div {
    margin-top: 0;
  }

  input[type='text'] {
    height: 35px;
  }
}

.tag-select-menu-container {
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  position: fixed;
  right: 0;
  z-index: 9;
  border-bottom: 0.25px solid #000000;
  overflow: hidden;

  .tag-select-menu {
    overflow: hidden;

    .tag-select-menu-items {
      max-height: 195px;
      height: fit-content;
      overflow: auto;

      .remove-tag-icon {
        display: none;
      }

      .tag-menu-item,
      li {
        width: 100%;
        height: 35px;
        background: white;

        &.selected-tag {
          background-color: #f7f7f7;

          .remove-tag-icon {
            display: inline-block;
          }

          &:hover {
            .remove-tag-icon {
              opacity: 0.5;
            }
          }
        }

        &:last-child {
          a {
            border-bottom: none;
          }
        }

        a {
          padding: 8px 30px;
          font-size: 0.75rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border: 0.25px solid #000000;
          border-top: none;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: #f7f7f7;
          }

          .tag-info-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            column-gap: 8px;

            .tag-color {
              width: 14px;
              height: 14px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
