.announcement-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 20px;
  width: 100%;

  .announcement-timespan-input,
  .announcement-inputs-container,
  .announcement-content-inputs-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    column-gap: 95px;
    flex-wrap: wrap;

    .announcement-name-container {
      width: 209px;
      word-break: break-word;

      .announcement-name-copy {
        margin-top: 6px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.466667px;
        color: #6c6c6c;
      }
    }

    .date-time-picker-container {
      max-width: 184px;
    }
  }

  .announcement-inputs-container {
    column-gap: 70px;
  }

  .announcement-image-container {
    max-width: 352px;

    .announcement-image-label {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 1.1375px;
      color: #6c6c6c;
      margin-block-end: 7px;
      display: block;
    }

    .announcement-image-copy {
      margin-top: 7px;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.466667px;
      color: #6c6c6c;
    }
  }

  .announcement-input {
    width: 184px;
  }

  .announcement-input,
  .announcement-area {
    input {
      height: 35px;
    }

    label {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 22px;
      letter-spacing: 1.1375px;
      color: #6c6c6c;
    }
  }

  .announcement-area {
    width: 100%;
    max-width: 440px;

    label + div + div {
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    .announcement-timespan-input,
    .announcement-inputs-container,
    .announcement-content-inputs-container {
      column-gap: 28px;
      row-gap: 20px;
    }

    .announcement-inputs-container {
      column-gap: 28px;
    }

    .announcement-name-container {
      word-break: break-word;
      width: 100%;
      .announcement-name-copy {
        width: 305px;
        margin-top: 6px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.466667px;
        color: #6c6c6c;
      }
    }
    .date-time-picker-container {
      width: 137px;

      .p-4 {
        padding: 6px;
        height: 35px;
      }
      .text-sm {
        font-size: 0.75rem;
      }
    }

    .announcement-image-container {
      max-width: 305px;

      span {
        color: #000;

        font-family: Lato;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 169.231% */
        letter-spacing: 1.138px;
        text-transform: uppercase;
      }
      i {
        color: #000;
        font-family: Lato;
        font-style: italic;
      }

      .button.select-media-button {
        width: 305px;
        height: 159px;
        margin-top: 10px;
        background: #fafafc;
        border: 1px solid #979797;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        border-radius: 0;
        font-size: 11px;
        line-height: 13px;
        color: #707982;

        .media-upload-icon {
          margin-bottom: 6px;
        }
      }
    }

    .announcement-input {
      width: 100%;
      max-width: 305px;
    }

    .announcement-area {
      width: 305px;
      max-width: 100%;

      .mt-4.h-3\/5 {
        height: 161px !important;
      }
    }
  }
}
