.menu-selection-bar-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-top: 20px;

  .plus-icon {
    min-width: 16px;
    margin-right: 6px;
  }

  & > .arrow-icon {
    margin-right: 6px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  .menu-selection-bar-scroll-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    overflow: scroll;
    min-height: 50px;

    .menu-selection-name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 10.5px 0 0;
      padding: 12px 16px 12px 0;
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 3px;
      color: black;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 4px;
      white-space: nowrap;

      &.is-hidden {
        color: #b8b8b8;
      }

      & span {
        margin-left: 10px;
      }

      .context-menu-action-container {
        margin-left: 15px;
        & .three-dot-icon {
          visibility: hidden;
        }
      }

      &.non-dragging:hover {
        color: black;
        background-color: #efebeb;

        .context-menu-action-container {
          & .three-dot-icon {
            visibility: visible;
          }
        }
      }

      &.active {
        color: white;
        background-color: black;
        font-weight: 400;

        &.is-hidden {
          color: #e8e8e8;
          background-color: #646464;
        }

        .context-menu-action-container {
          & .three-dot-icon {
            visibility: visible;
          }
        }
      }
    }
  }
}

// mobile version
@media (max-width: 768px) {
  .menu-selection-bar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    padding-top: 0px;
    height: 50px;

    .plus-icon {
      min-width: 17px;
      margin-right: 0px;
      margin-left: -20px;
    }

    & > .arrow-icon {
      margin-right: 6px;
      &:hover {
        background-color: #f7f7f7;
      }
    }
    .menu-selection-bar-scroll-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      overflow: scroll;
      height: 40px;

      .menu-selection-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 10.5px 0 0;
        padding: 7px 12px 7px 0;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
        color: black;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 4px;
        white-space: nowrap;

        &.is-hidden {
          color: #b8b8b8;
        }

        & span {
          font-family: Lato;
          font-size: 12px;
          margin-left: 0px;
          font-style: normal;
          font-weight: 300;
          letter-spacing: 1px;
        }

        & svg {
          margin-top: 1px;
          width: 2px;
          height: 8px;
        }

        .context-menu-action-container {
          margin-left: 1px;
          & .three-dot-icon {
            visibility: hidden;
          }
        }

        &.non-dragging:hover {
          color: black;
          background-color: #efebeb;

          .context-menu-action-container {
            & .three-dot-icon {
              visibility: visible;
            }
          }
        }

        &.active {
          color: white;
          background-color: black;

          span {
            font-weight: 700;
            font-family: Lato;
            font-style: bold;
          }

          &.is-hidden {
            color: #e8e8e8;
            background-color: #646464;
          }

          .context-menu-action-container {
            & .three-dot-icon {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
