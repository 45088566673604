.modifier-groups-modal {
  border-radius: 20px;
  width: 45%;
  min-width: 500px;
  line-height: 22px;
  padding: 15px 25px;
  cursor: initial;
  height: 85%;
  overflow: auto;

  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;

    & svg {
      align-self: flex-end;
    }

    .modal-title {
      letter-spacing: 1.1375px;
    }
  }

  .modal-body {
    padding: 2px 10px;
    overflow: scroll;

    .modifier-group-display-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 10px 0;

      .no-modifier-group-copy {
        margin: auto;
        font-size: 14px;
        color: #9E9E9E;
        font-style: italic;
        font-weight: 600;
        text-align: center;
      }

      .modifier-group-display-list {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        height: 525px;
        padding: 8px 16px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .create-modifier-group-button {
        height: 35px;
        background: #05944f;
        width: fit-content;
        padding: 0 10px;
        font-size: 0.75rem;
        font-weight: 600;
        align-self: flex-end;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1.41818px;
        line-height: 14px;
        border-radius: 3px;
      }
    }

    .modifier-group-builder-container {
      margin-top: 10px;
      border-top: 1px solid #dedede;
      padding-top: 8.5px;

      .modal-message {
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 0.75rem;
        color: #000000;
        margin-bottom: 10px;
      }

      .modifier-group-form {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .modifier-group-inputs {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 20px;
          width: 100%;

          .modifier-group-input {
            width: calc(50% - 10px);

            label {
              font-size: 0.775rem;
            }

            input[type=text] {
              height: 35px;
            }
          }
        }

        .selector-container {
          border-top: 1px solid #dedede;
          padding-top: 20px;
          margin-bottom: 15px;

          .entity-selector-container {
            max-height: 250px;
          }
        }

        .modifier-group-builder-buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          column-gap: 18px;

          .submit-modifier-group-button,
          .cancel-modifier-group-button {
            color: white;
            width: 132px;
            height: 35px;
            letter-spacing: 1.41818px;
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 14px;
            border-radius: 3px;
          }

          .submit-modifier-group-button {
            background-color: #21377a;
          }

          .cancel-modifier-group-button {
            background-color: #e74c3c;
            transition: background-color 0.3s ease;
            margin-right: 12px;

            &:hover {
              background-color: #a73333;
            }
          }
        }
      }
    }
  }
}
