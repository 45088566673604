.restaurant-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .restaurant-description-hours-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 50px;


    & > div {
      min-width: 120px;
      max-width: 370px;
    }

    .restaurant-info-header {
      font-weight: 400;
      font-size: .6875rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      color: #8F8E94;
      margin-bottom: 9px;
    }

    .day-hour-display-container.restaurant-info-hours {
      max-width: 400px;

      .hours-label {
        font-weight: 400;
        font-size: .6875rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        color: #8F8E94;
        margin-bottom: 9px;
      }

      .day-hour-label, .day-hour-time-value {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 65px;
      }
    }
    .restaurant-info-value-display {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #000000;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 65px;
    }
  }
  .restaurant-info-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 75px;

    & > div {
      min-width: 120px;
      max-width: 370px;
    }

    .restaurant-image-display {
      width: 100px;
      height: 100px;
      aspect-ratio: 4/6;
      object-fit: cover;
    }

    .restaurant-info-header {
      font-weight: 400;
      font-size: .6875rem;
      line-height: 22px;
      letter-spacing: 0.9625px;
      color: #8F8E94;
      margin-bottom: 9px;
    }

    .day-hour-display-container.restaurant-info-hours {
      max-width: 450px;

      .hours-label {
        font-weight: 400;
        font-size: .6875rem;
        line-height: 22px;
        letter-spacing: 0.9625px;
        color: #8F8E94;
        margin-bottom: 9px;
      }

      .day-hour-label, .day-hour-time-value {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 65px;
      }
    }
    .restaurant-info-value-display {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #000000;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 65px;
    }
  }
}
