.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(240, 240, 240, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;

  &-content {
    width: 500px;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
  }

  &-header,
  &-footer {
    padding: 10px;
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & button {
      width: 100px;
      height: 35px;
      font-size: 0.75rem;
    }
  }

  &-title {
    margin: 0;
    font-weight: 700;
    font-size: 0.8125rem;
    text-transform: uppercase;
  }

  &-body {
    font-size: 0.75rem;
    padding: 10px;
    letter-spacing: 0.466667px;
    word-break: break-word;
  }

  // mobile devices
  @media (max-width: 768px) {
    overflow-y: auto;
    position: fixed;
    top: 50px;
    background-color: white;

    &-content {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 0;
    }

    &-header,
    &-footer {
      padding: 10px 20px;
    }

    &-footer {
      justify-content: center;
    }

    &-title {
      font-size: 1rem;
    }

    &-body {
      font-size: 0.875rem;
    }
  }
}
