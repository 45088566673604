.date-time-picker-container {
  width: 100%;

  .date-time-picker-label {
     font-weight: 500;
     font-size: 0.8125rem;
     line-height: 22px;
     letter-spacing: 1.1375px;
     color: #6C6C6C;
   }

  .date-time-picker-input {
    height: 35px;
  }
}
